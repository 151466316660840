import { r, d, h, a } from "@/units"
import { onMounted, getCurrentInstance, ref, watch, render, nextTick, computed, createVNode, TransitionGroup } from 'vue'

import './index.less'
export default {
  name: "ITable",
  props: {
    option: Object,
    id: String,
    staticData: Object
  },
  emits: ['finished'],
  setup(props, context) {
    const instance: any = getCurrentInstance()
    const tableBoxRef = ref<any | null>(null)
    const tableRef = ref<any | null>(null)
    const state = {
      option: {
        row: props.option.row,
        dataset: props.option.dataset || [],
        grid: props.option.grid,
        thead: props.option.thead,
      },
    }
    const colspanData = (data: any) => {
      if (!data) return
      //合并行
      let colspanData = [];
      for (let i = 0; i < data.length; i++) {
        let rowsArray = [];
        second: for (let j = 0; j < data[i].length; j++) {
          // if (data[0][j] == 'group') if (data[i][j]) break second  // 不显示 group
          let obj = {
            name: data[i][j],
            colspan: 1, //列
            rowspan: 1, //行
          }
          rowsArray.push(obj)
        }
        colspanData.push(rowsArray);
      }
      //合并列
      return colspanData;
    }
    let tableData: any = colspanData(state?.option?.dataset?.source)
    let rowBackground: any = (index) => state.option.row.splitArea.show && index % 2 === 0 ? state.option.row.rowBackground : 'transparent'
    let theadShow = state.option.thead.show
    const tableGrid: any = (type) => {
      return state.option.grid[0][type]
    }
    const setAutoHeight = () => state.option.row.rowAutoHeight ? '100%' : 'initial'
    let setHeight = (key) => state.option.row.rowAutoHeight ? 'inherit' : state.option.row.rowHeight + 'px'


    const setOption = (data, isAppend) => { //分组关键字
      setHeight = (key) => data.row.rowAutoHeight ? 'inherit' : data.row.rowHeight + 'px'
      rowBackground = (index) => data.row.splitArea.show && index % 2 === 0 ? data.row.rowBackground : 'transparent'

      instance.update()
      tableBoxRef.value.style.paddingTop = data.grid[0].top
      tableBoxRef.value.style.paddingBottom = data.grid[0].bottom
      tableBoxRef.value.style.paddingLeft = data.grid[0].left
      tableBoxRef.value.style.paddingRight = data.grid[0].right

      console.log('data.dataset.source', data.dataset.source)
      let getData
      if (data.dataset.source.length == 0) {
        getData = props.staticData
      } else {
        getData = data.dataset
      }

      // if(!tableData) return
      console.log('props.staticData', props.staticData)
      tableData = colspanData(getData.source)
      render(MTable(tableData, null), tableBoxRef.value)
      // new removeInitData(tableBoxRef.value)


      theadShow = data.thead[0].show
      const theadEL = tableBoxRef.value.firstChild.firstChild
      if (theadEL) {
        theadShow ? theadEL.style.visibility = 'visible' : theadEL.style.visibility = 'hidden'
      }
      
    }


    onMounted(() => {
      instance.proxy.$el.__vueComponent = instance
      instance.setOption = setOption

    })

    const removeInitData = (el: any) => {
      if (el.childNodes.length > 1) el.removeChild(el.childNodes[0])
    }

    const Colgroup: any = (ds) => ds.map((itemTr, indexTr) => {
      return createVNode('tr', {
        style: {
          backgroundColor: rowBackground(indexTr),
          width: '100%',
          lineHeight: setHeight(indexTr),
        },
        key: itemTr,
        dataIndex: indexTr
      },
        itemTr.map((itemTd, indexTd) => {
          return createVNode('td', {
            key: itemTd
          },
            itemTd.name
          )
        })
      )
    })

    const MBox = (ds) => h('div', {
      id: props.id,
      ref: tableBoxRef,
      style: {
        width: '100%',
        height: '100%',
        overFlow: 'hidden',
        paddingTop: tableGrid('top'),
        paddingLeft: tableGrid('left'),
        paddingRight: tableGrid('right'),
        paddingBottom: tableGrid('bottom')
      }
    },
      // MTable(ds, null)
    )
    const MTable = (ds, ids) => createVNode('table',
      {
        // tag: 'table',
        // name: "staggered-fade",
        className: ids,
        ref: tableRef,
        // css: false,
        // onEnter: enter,
        // onBeforeEnter: beforeEnter,
        // onLeave: leave,
        style: {
          width: '100%',
          height: setAutoHeight(),
          whiteSpace: 'nowrap',
        },
      },
      Colgroup(ds)
    )

    const VNode = {
      render: () => MBox(tableData)
    }

    context.emit('finished', () => { return true });
    return () => createVNode(VNode)
  }
}

import { r, d, h, a } from "@/units"
import { onMounted, getCurrentInstance, ref, watch, render, nextTick, computed, createVNode, TransitionGroup } from 'vue'
import type { PropType } from 'vue'
import { FormItemProps, FormItemInstance } from 'ant-design-vue/lib/form'
import { SwitchProps } from 'ant-design-vue/lib/switch'
import { Switch, Form, FormItem } from 'ant-design-vue'
// import { IFormOption  } from '@/store/modules/editor'
import './index.less'
export default {
  name: "formswitch",
  props: {
    option: {
      type: Object as PropType<FormItemProps>,
      dataset: Object
    },
    // staticData: Array,
    id: String,
  },
  emits: ['finished'],
  setup(props, context) {
    const instance: any = getCurrentInstance()
    const boxRef = ref<any | null>(null)
    const switchRef = ref<any | null>(null)
    const formRef = ref<any | null>(null)
    const state = {
      option: {
        type: props.option.type
      }
    }
    const setOption = (data, isAppend) => { //分组关键字
      state.option = { ...data }
      boxRef.value.__vueComponent.props.option = { ...data }
      /* 删除 data 中的 dataset 对象 */

      delete data.dataset
      instance.update()
      
      render(createVNode(Forms(data)), boxRef.value)

      // new removeInitData(boxRef.value)
    }

    onMounted(() => {
      instance.proxy.$el.__vueComponent = instance
      instance.setOption = setOption
      console.log(instance)
    })

    const removeInitData = (el: any) => {
      if (el.childNodes.length > 1) el.removeChild(el.childNodes[0])
    }

    const _check = ref(false);

    const changeSize = (data: any) => {
      if(!data) return
      if(data == 'small') return { 'fontSize': '12px'}
      if(data == 'default') return { 'fontSize': '14px'}
      if(data == 'large') return { 'fontSize': '16px', 'lineHeight': 2.5}
    }

    const Forms = (data) => h(Form, {
      ref: formRef,
    },
      () => h(FormItem, {
        labelCol:{ style: changeSize(data.size) },
        ...(data as FormItemProps)
      },
        () => h(Switch, {
          ref: switchRef,
          checked: _check.value,
          onChange: (e: any) => {
            _check.value = e
            // if (data.mode == 'IO') {} else {}
            
            let values = {}
            if(!e.target) {
              data.mode == 'IO' ? values = Number(e) : values = e
            } else {
              data.mode == 'IO' ? values = { target: { value: Number(e) } } : values = { target: { value: e } }
            }
            instance.isValue = e
            context.emit('change', values);
          },
          ...(data as SwitchProps)
        })
      )
    )

    const VNode = {
      render: () => h('div', {
        id: props.id,
        ref: boxRef,
      },
        () => Forms
      )
    }

    context.emit('finished', () => { return true });
    return () => h(VNode)
  }
}

import {
  ComponentInternalInstance,
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
} from 'vue'

/**
 * @description: instance实例类型
 */
interface InstanceType extends ComponentInternalInstance {
  setOption: Function
}

/**
 * @description: state类型
 */
interface IState {
  option: Record<string, any>
}

export default defineComponent({
  name: 'TeamKanbanInfo',
  props: {
    option: {
      type: Object, // 通过 init 获取来的初始值
    },
    staticData: Array,
  },

  setup(props, { emit }) {
    const instance = getCurrentInstance() as InstanceType // 获取组件实体
    const state = reactive<IState>({
      option: {
        type: props.option.type,
        dataset: props.option.dataset || [],
      },
    })

    const setOption = data => {
      //获取更新数据 后自有逻辑
      state.option = { ...props.option, ...data }
      if (!data.dataset.source.length) data.dataset = props.staticData
    }

    const init = () => {
      // 初始化赋值
      instance.proxy.$el.__vueComponent = instance //挂载组件实体
      instance.setOption = setOption //注册setOption
      console.log('props---', props)
      emit('finished')
    }

    onMounted(() => {
      init()
    })

    return () => <div class="team-suite-info">当前套件信息</div>
  },
})

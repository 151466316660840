import { r, d, h, a } from "@/units"
import { onMounted, getCurrentInstance, ref, watch, render, nextTick, computed, createVNode, TransitionGroup } from 'vue'
import type { PropType } from 'vue'
import { FormItemProps, FormItemInstance } from 'ant-design-vue/lib'
import type { RangePickerProps as BaseRangePickerProps } from 'ant-design-vue/lib/date-picker/generatePicker'
// import { RangePickerProps } from 'ant-design-vue/lib/date-picker/generatePicker'
import { TimeRangePicker, Form, FormItem, DatePicker} from 'ant-design-vue'

const { RangePicker } = DatePicker
import _ from 'lodash'
// import { IFormOption  } from '@/store/modules/editor'
import './index.less'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn';
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import { RangePickerProps } from "ant-design-vue/lib/date-picker/generatePicker/props"
type RangeValue = [Dayjs, Dayjs]
dayjs.locale('zh-cn');

export default {
  name: "IRangePicker",
  props: {
    option: {
      type: Object as PropType<FormItemProps>,
      dataset: Object
    },
    id: String,
    staticData: Object
  },
  emits: ['finished'],
  setup(props, context) {
    const instance: any = getCurrentInstance()
    const boxRef = ref<any | null>(null)
    const timeRangePickerRef = ref<any | null>(null)
    const formRef = ref<any | null>(null)
    const state = {
      option: {
        type: props.option.type
      },
      optionData: []
    }

    const coverData = (data: any) => {
      if (!data || !data[0]) return []
      let CData = []
      for (let i = 0; i < data[0].length; i++) {
        let obj = {
          "value": data[0][i]
        }
        CData.push(obj)
      }
      return CData
    }
    
    const value = ref<RangeValue>()

    const setOption = (data, isAppend) => { //分组关键字
      state.option = { ...data }
      const selectData: any = _.cloneDeep(data)
      delete selectData.dataset
      selectData.options = props.staticData.source || []
      instance.update() 
      state.optionData = selectData.options
      let _isVal = []
      instance.isValue = _isVal
      render(createVNode(Forms(selectData)), boxRef.value)
      if(!selectData.options[0]) return
      value.value = [
        dayjs(selectData.options[0][0]),
        dayjs(selectData.options[0][1])
      ]

    }

    onMounted(() => {
      instance.proxy.$el.__vueComponent = instance
      instance.setOption = setOption
    })

    const changeSize = (data: any) => {
      if(!data) return
      if(data == 'small') return { 'fontSize': '12px'}
      if(data == 'default') return { 'fontSize': '14px'}
      if(data == 'large') return { 'fontSize': '16px', 'lineHeight': 2.5}
    }
   
    const Forms = (data) => h(Form, {
      ref: formRef,
    },
      () => h(FormItem, {
        labelCol:{ style: changeSize(data.size) },
        ...(data as FormItemProps)
      },
        () => h(RangePicker, {
          ref: timeRangePickerRef,
          // allowEmpty:[true, true],
          locale,
          value:value.value,
          // value: [
          //   dayjs('2015/01/01', 'YYYY/MM/DD'),
          //   dayjs('2015/01/01', 'YYYY/MM/DD'),
          // ],
          // mode:['date', 'date'],
          // picker:['date'],
          // showTime: true,
          // getPopupContainer: (trieggerNode) => trieggerNode.parentNode as HTMLElement,
          // defaultValue: value.value,
          valueFormat: 'YYYY-MM-DD',
          format: 'YYYY-MM-DD',
         
          // defaultPickerValue:value.value,
          onChange: (e) => {

            let value = { target: { value: e } }
            instance.isValue = e
            context.emit('change', value);
          },
          ...(data as RangePickerProps<any>)
        })
      )
    )

    const VNode = {
      render: () => h('div', {
        id: props.id,
        ref: boxRef,
      },
        () => Forms
      )
    }

    context.emit('finished', () => { return true });
    return () => h(VNode)
  }
}

import { EChartsOption } from 'echarts'

export const source = [
  ['站位1', 124],
  ['站位2', 118],
  ['站位3', 113],
  ['站位4', 104],
  ['站位5', 102],
  ['站位6', 109],
  ['站位7', 109]
]
export const options = {
  backgroundColor: '',
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'line'
    },
    backgroundColor: '#0E2544',
    textStyle: {
      color: 'rgba(255,255,255,.8)'
    }
  },
  xAxis: {
    type: 'category',
    triggerEvent: true,
    boundaryGap: false,
    splitLine: {
      show: false
    }
  },
  yAxis: {
    minInterval: 100,
    splitLine: {
      lineStyle: {
        type: 'dashed'
      }
    },
    type: 'value',
    axisTick: {
      inside: true
    },
    axisLabel: {
      inside: true,
      formatter: '{value}\n'
    },
    z: 10
  },
  grid: [
    {
      top: '20%',
      left: '8%',
      bottom: '20%',
      right: '4%'
    }
  ],
  series: [
    {
      areaStyle: {
        color: {
          x: 0,
          y: 0,
          y2: 1,
          global: false,
          x2: 0,
          colorStops: [
            {
              offset: 0,
              color: 'rgba(36,168,255,0.8)'
            },
            {
              offset: 1,
              color: 'rgba(6,99,255,0.1)'
            }
          ],
          type: 'linear'
        }
      },
      emphasis: {
        focus: 'series'
      },
      symbol: 'circle',
      symbolSize: 15,
      showAllSymbol: true,
      showSymbol: false,
      lineStyle: {
        color: '#24a8ff',
        width: 2.67,
        type: 'solid'
      },
      itemStyle: {
        color: {
          type: 'radial',
          x: 0.5,
          y: 0.5,
          r: 0.5,
          colorStops: [
            {
              offset: 0,
              color: '#53BAFF'
            },
            {
              offset: 0.01,
              color: '#fff'
            },
            {
              offset: 0.8,
              color: '#fff'
            },
            {
              offset: 1,
              color: '#fff'
            }
          ]
        },
        borderColor: '#53BAFF',
        borderWidth: 1.33
      },
      smooth: true,
      type: 'line'
    }
  ],
  dataset: {
    source
  }
}

import { r, d, h, a } from "@/units"
import { onMounted, getCurrentInstance, ref, watch, render, nextTick, computed, createVNode, TransitionGroup } from 'vue'
import type { PropType } from 'vue'
import { FormItemProps, FormItemInstance } from 'ant-design-vue/lib/form'
import { ButtonProps } from 'ant-design-vue/lib/button'
import { Button, Form, FormItem } from 'ant-design-vue'
import _ from 'lodash'
import './index.less'
export default {
  name: "IButton",
  props: {
    option: {
      type: Object as PropType<FormItemProps>,
      inner: String,
      dataset: Object
    },
    // staticData: Array,
    id: String,
  },
  emits: ['finished'],
  setup(props, context) {
    const instance: any = getCurrentInstance()
    const boxRef = ref<any | null>(null)
    const buttonRef = ref<any | null>(null)
    const formRef = ref<any | null>(null)
    const state = {
      option: {
        type: props.option.type,
        inner: props.option.inner
      }
    }
    const setOption = (data, isAppend) => { //分组关键字

      const selectData: any = _.cloneDeep(data)
      delete selectData.dataset

      instance.update()

      render(createVNode(Forms(selectData)), boxRef.value)

    }

    onMounted(() => {
      instance.proxy.$el.__vueComponent = instance
      instance.setOption = setOption
      console.log(instance)
    })
    let TYPE:any = {
      mold: "type"
    }
    const Forms = (data) => h(Form, {
      ref: formRef,
    },
      () => h(FormItem, {
        // ...(data as FormItemProps)
      },
        () => h(Button, {
          ref: buttonRef,
          [TYPE['mold']]: 'primary',
          onClick: (e: any) => {
            context.emit('change', e);
          },
          ...(data as ButtonProps)
        }, () => props.option.label)
      )
    )

    const VNode = {
      render: () => h('div', {
        id: props.id,
        ref: boxRef,
      },
        () => Forms
      )
    }

    context.emit('finished', () => { return true });
    return () => h(VNode)
  }
}

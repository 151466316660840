import { IApiOptions, IObject } from '@/types'

export const queueTask = (time:number, cb:Function) => {
  let timer = null
  if (timer) {
    clearTimeout(timer)
  }
  cb()
  timer = setTimeout(() => {
    queueTask(time, cb)
    clearTimeout(timer)
  }, time)

}

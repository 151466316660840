import { r, d, h, a } from "@/units"
import { onMounted, getCurrentInstance, ref, watch, render, nextTick, computed, createVNode, TransitionGroup } from 'vue'
import type { PropType } from 'vue'
import { FormItemProps, DatePickerProps } from 'ant-design-vue/lib'
import { TimeRangePicker, Form, FormItem, DatePicker } from 'ant-design-vue'

import _ from 'lodash'
import './index.less'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';

export default {
  name: "IDatePicker",
  props: {
    option: {
      type: Object as PropType<FormItemProps>,
      dataset: Object
    },
    id: String,
    staticData: Object
  },
  emits: ['finished'],
  setup(props, context) {
    const instance: any = getCurrentInstance()
    const boxRef = ref<any | null>(null)
    const datePickerRef = ref<any | null>(null)
    const formRef = ref<any | null>(null)
    const state = {
      option: {
        type: props.option.type
      }
    }
    const setOption = (data, isAppend) => { //分组关键字
      state.option = { ...data }
      boxRef.value.__vueComponent.props.option = { ...data }
      /* 删除 data 中的 dataset 对象 */


      const selectData = _.cloneDeep(data)
      delete selectData.dataset
      // console.log('[]??????????', coverData(props.staticData.source)) // 不是dataset 

      instance.update()

      render(createVNode(Forms(selectData)), boxRef.value)

      // new removeInitData(boxRef.value)
    }

    onMounted(() => {
      instance.proxy.$el.__vueComponent = instance
      instance.setOption = setOption
    })

    const changeSize = (data: any) => {
      if(!data) return
      if(data == 'small') return { 'fontSize': '12px'}
      if(data == 'default') return { 'fontSize': '14px'}
      if(data == 'large') return { 'fontSize': '16px', 'lineHeight': 2.5}
    }

    const Forms = (data) => h(Form, {
      ref: formRef,
    },
      () => h(FormItem, {
        class: "datePicker",
        labelCol:{ style: changeSize(data.size) },
        ...(data as FormItemProps)
      },
        () => h(DatePicker, {
          ref: datePickerRef,
          locale,
          // valueFormat: 'YYYY-MM-DD',
          onChange: (e) => {
            let value = { target: { value: e } }
            console.log('#################################',e)
            instance.isValue = e
            context.emit('change', value);
          },
          ...(data as DatePickerProps)
        })
      )
    )

    const VNode = {
      render: () => h('div', {
        id: props.id,
        ref: boxRef,
      },
        () => Forms
      )
    }

    context.emit('finished', () => { return true });
    return () => h(VNode)
  }
}

import {
  defineComponent,
  reactive,
  SetupContext,
  PropType,
  computed,
  onMounted,
  TransitionGroup,
  onUnmounted,
  getCurrentInstance
} from 'vue'
import { IBoardContentItem, IState } from './types'
import './index.less'

// 停机图片
const ShutdownImg = require('./images/shutdown.png')

// 组件
class RenderComponent {
  // 头部组件
  static Header ({ data }) {
    return (
      <div class='board-items__header'>
        <div class='board-items__header__name'>{data.title}</div>
        {data.status == 1 && (
          <div class='board-items__header__emergency'>急</div>
        )}
      </div>
    )
  }
  static Content ({ data }) {
    return (
      <div class='board-items__content'>
        <div class='board-items__content__item'>
          <div class='board-items__content__item__name'>款式名称</div>
          <div class='board-items__content__item__value'>{data.style}</div>
        </div>
        <div class='board-items__content__item'>
          <div class='board-items__content__item__name'>款号</div>
          <div class='board-items__content__item__value'>{data.orderNo}</div>
        </div>

        <div class='board-items__content__footer'>
          <div class='item'>
            <span class='name ml-14'>待做数量</span>
            <div class='value ml-14'>{data.toBeDone}</div>
          </div>
          <div class='item'>
            <span class='name ml-24'>总产量</span>
            <div class='value ml-24'>{data.totalOutput}</div>
          </div>
        </div>
      </div>
    )
  }
  static Image () {
    return <img src={ShutdownImg} class='background-img' alt='' />
  }
}

export default defineComponent({
  name: 'StationList',
  props: {
    option: {
      type: Object // 通过 init 获取来的初始值
    },
    staticData: Array
  },
  setup (props, { emit }) {
    const instance: any = getCurrentInstance() // 获取组件实体

    const state = reactive<IState>({
      boardItemInfo: {},
      option: {
        type: props.option.type,
        dataset: props.option.dataset || []
      }
    })

    const setOption = data => {
      //获取更新数据 后自有逻辑
      state.option = { ...data }
      if (data.dataset.source.length == 0) data.dataset = props.staticData
      // ! 兼容老版本的模式、老版本模式为data、后续采用source
      state.boardItemInfo = data.dataset.source?.[0] || data.dataset?.data?.[0]
    }

    const init = () => {
      // 初始化赋值
      instance.proxy.$el.__vueComponent = instance //挂载组件实体
      instance.setOption = setOption //注册setOption
    }

    onMounted(() => {
      init()
      emit('finished')
    })
    // 停机 type： 0==>停机   1==>正常
    // 加急 status：  0==>不加急  1==>加急
    return () => (
      <div class='board-items'>
        <RenderComponent.Header data={state.boardItemInfo} />
        {state.boardItemInfo.type == 1 ? (
          <RenderComponent.Content data={state.boardItemInfo} />
        ) : (
          <RenderComponent.Image />
        )}
      </div>
    )
  }
})

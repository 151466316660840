import { r, d, h, a } from "@/units"
import { onMounted, getCurrentInstance, ref, customRef, watch, render, nextTick, computed, createVNode, TransitionGroup } from 'vue'
import type { PropType } from 'vue'
import { FormItemProps, InputNumberProps } from 'ant-design-vue/lib'
import type { RangePickerProps as BaseRangePickerProps } from 'ant-design-vue/lib/date-picker/generatePicker'
// import { RangePickerProps } from 'ant-design-vue/lib/date-picker/generatePicker'
import { InputNumber, Form, FormItem } from 'ant-design-vue'
import _ from 'lodash'
// import { IFormOption  } from '@/store/modules/editor'
import './index.less'


export default {
  name: "IDigitalRange",
  props: {
    option: {
      type: Object as PropType<FormItemProps>,
      dataset: Object
    },
    id: String,
    staticData: Object
  },
  emits: ['finished', 'change'],
  setup(props, context) {
    const instance: any = getCurrentInstance()
    const boxRef = ref<any | null>(null)
    const numberRangeMinRef = ref<any | null>(null)
    const numberRangeMaxRef = ref<any | null>(null)
    const formRef = ref<any | null>(null)
    const state = {
      option: {
        type: props.option.type
      },
      optionData: [],
      min: null,
      max: null
    }
    const value = ref([])
    const setOption = (data, isAppend) => { //分组关键字


      state.option = { ...data }
      const selectData: any = _.cloneDeep(data)
      delete selectData.dataset
      selectData.options = props.staticData.source || []
      instance.update() 
      state.optionData = selectData.options
      let _isVal = []
      instance.isValue = _isVal
      render(createVNode(Forms(selectData)), boxRef.value)
      if(!selectData.options[0]) return
      value.value = [
        selectData.options[0][0],
        selectData.options[0][1]
      ]
    }

    onMounted(() => {
      instance.proxy.$el.__vueComponent = instance
      instance.setOption = setOption
    })

    const debounce = (fn, delay = 0, immediate = false) => {
      let timeout
      return (...args) => {
        if (immediate && !timeout) fn(...args)
        clearTimeout(timeout)

        timeout = setTimeout(() => {
          fn(...args)
        }, delay)
      }
    }

    const useDebouncedRef = (initialValue, delay, immediate) => {
      const state = ref(initialValue)
      const debouncedRef = customRef((track, trigger) => ({
        get() {
          track()
          return state.value
        },
        set: debounce(
          value => {
            state.value = value
            trigger()
          },
          delay,
          immediate
        ),
      }))
      return debouncedRef
    }

    const min = useDebouncedRef(null, 400, false)
    const max = useDebouncedRef(null, 400, false)

    watch([min, max], ([mins, maxs]) => {
      if (mins && maxs) {
        context.emit('change', { target: { value: [mins,maxs] } })
        instance.isValue = [mins,maxs]
      } else {
        context.emit('change', { target: { value: "" } })
        instance.isValue = ""
      }
    })

    const changeSize = (data: any) => {
      if(!data) return
      if(data == 'small') return { 'fontSize': '12px'}
      if(data == 'default') return { 'fontSize': '14px'}
      if(data == 'large') return { 'fontSize': '16px', 'lineHeight': 2.5}
    }

    const Forms = (data) => h(Form, {
      ref: formRef,
    },
      () => h(FormItem, {
        layout: "horizontal",
        class: "digitalRange",
        style: {
        },
        labelCol:{ style: changeSize(data.size) },
        ...(data as FormItemProps)
      },
        () => [
          h(InputNumber, {
            style: { width: 'auto' },
            ref: numberRangeMinRef,
            value: value.value[0],
            controls: false,
            stringMode: true,
            onChange: (value) => {
              state.min = value as any
              min.value = value
            },
            size: data.size
          }),
          h('span', { style: { width: '20px', textAlign: 'center' }, }, '-'),
          h(InputNumber, {
            style: { width: 'auto' },
            ref: numberRangeMaxRef,
            value: value.value[1],
            controls: false,
            stringMode: true,
            onChange: (value) => {
              state.max = value as any
              max.value = value
            },
            size: data.size
          }),
        ]
      )
    )

    const VNode = {
      render: () => h('div', {
        id: props.id,
        ref: boxRef
      },
        () => Forms
      )
    }
    context.emit('finished', () => { return true });
    return () => h(VNode)
  }
}

import { r, d, h, a } from '@/units'
import { onMounted, getCurrentInstance, ref, watch, render, nextTick, computed, createVNode, reactive } from 'vue'

import { Button, Form, FormItem } from 'ant-design-vue'
import * as XLSX from 'xlsx'
import { cloneDeep } from 'lodash'
import './index.less'

export default ({
  name: 'OutExcel',
  props: {
    option: {
      type: Object
    },
  },
  setup(props, context) {
    const instance: any = getCurrentInstance()
    const boxRef = ref<any | null>(null)
    const buttonRef = ref<any | null>(null)
    // const XlsxSheetRef = ref<any | null>(null)
    const formRef = ref<any | null>(null)
    const state = {
      option: {
        type: props.option.type,
        dataset: props.option.dataset
      },
    }
    const setOption = (data, isAppend) => { //分组关键字
      console.log('Excel setOption', data)
      const selectData: any = cloneDeep(data)
      instance.update()
      render(createVNode(Buttons(data)), boxRef.value)
      if (data?.hasQueried) {
        const { columns } = selectData.upStream.option
        const sheet = selectData.dataset.data || []
        const columnsOne = columns.filter(item => item.isShow && item.children?.length)
        const _h1 = {}
        const _h2 = {}
        columns.forEach(item => {
          const { dataIndex, title, isShow, children } = item
          if (!isShow) return
          if (children?.length) {
            children.forEach(c => {
              _h2[c.dataIndex] = c.title || ''
            })
          } else {
            _h2[dataIndex] = title || ''
          }
        })
        Object.keys(_h2)?.forEach(key => {
          _h1[key] = columnsOne.find(item => item.children[0].dataIndex === key)?.title || ''
        })

        sheet?.unshift(_h2)
        Object.keys(_h1).length && (sheet?.unshift(_h1))
        exportFile(sheet, '新建Excel表格')
      }
    }
    onMounted(() => {
      instance.proxy.$el.__vueComponent = instance //挂载组件实体
      instance.setOption = setOption  //注册setOption
    })

    const exportFile = (sheetData, fileName) => {
      const sheet = XLSX.utils.json_to_sheet(sheetData, { skipHeader: true })
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, sheet, fileName)
      const workbookBlob = workbook2blob(wb)
      openDownload(workbookBlob, `${fileName}.xls`)
    }

    const openDownload = (blob, fileName) => {
      if (typeof blob === 'object' && blob instanceof Blob) {
        blob = URL.createObjectURL(blob) // 创建blob地址
      }
      const aLink = document.createElement('a')
      aLink.href = blob
      aLink.download = fileName || ''
      let event
      if (window.MouseEvent) event = new MouseEvent('click')
      //   移动端
      else {
        event = document.createEvent('MouseEvents')
        event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
      }
      aLink.dispatchEvent(event)
    }

    const workbook2blob = (workbook) => {
      const wopts: object = {
        bookType: 'xlsx',
        bookSST: false,
        type: 'binary',
      }
      const wbout = XLSX.write(workbook, wopts)

      // 将字符串转ArrayBuffer
      function s2ab(s: string) {
        const buf = new ArrayBuffer(s.length)
        const view = new Uint8Array(buf)
        for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
        return buf
      }

      const blob = new Blob([s2ab(wbout)], {
        type: 'application/octet-stream',
      })
      return blob
    }

    const Buttons = (data) => h(Form, {
        ref: formRef,
      },
      () => h(FormItem, {
          // ...(data as FormItemProps)
        },
        () => h(Button, {
          ref: buttonRef,
          type: 'primary',
          size: data.size,
          onClick: (e: any) => {
            context.emit('change', e)
          }
        }, () => '导出EXCEL')
      )
    )

    const VNode = {
      render: () => h('div', {
          id: props.id,
          ref: boxRef,
        }
      )
    }

    context.emit('finished', () => {
      return true
    })
    return () => h(VNode)
  },
})




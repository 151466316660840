import { r, d, h, a } from "@/units"
import { onMounted, getCurrentInstance, ref, watch, render, nextTick, computed, createVNode, TransitionGroup } from 'vue'
import type { PropType } from 'vue'
import { FormItemProps, FormItemInstance } from 'ant-design-vue/lib/form'
import { SelectProps } from 'ant-design-vue/lib/select'
import { Select, Form, FormItem } from 'ant-design-vue'
import _ from 'lodash'
// import { IFormOption  } from '@/store/modules/editor'
import './index.less'
import { getQueryVariable } from '@/utils'
export default {
  name: "ISelect",
  props: {
    option: {
      type: Object as PropType<FormItemProps>,
      dataset: Object
    },
    id: String,
    staticData: Object
  },
  emits: ['finished','change'],
  setup(props, context) {
    const instance: any = getCurrentInstance()
    const boxRef = ref<any | null>(null)
    const selectRef = ref<any | null>(null)
    const formRef = ref<any | null>(null)
    const state = {
      option: {
        type: props.option.type
      },
      selectedRowKeys: [],
      optionData: []
    }

    const setOption = (data, isAppend) => { //分组关键字
      state.option = { ...data }
      // boxRef.value.__vueComponent.props.option = { ...data }
      /* 删除 data 中的 dataset 对象 */


      const selectData: any = _.cloneDeep(data)
      delete selectData.dataset
      // console.log('[]??????????', coverData(props.staticData.source)) // 不是dataset
      // if(props.staticData.source.length == 0) return

      selectData.options = coverData(props.staticData.source) || []

      instance.update()
      state.optionData = selectData.options
      state.selectedRowKeys = selectData.options?.filter(item => !!item.check) || []

      //以下为获取url参数，并设为默认值的逻辑
      const { parameter } = data
      const urlValue = getQueryVariable(parameter)
      const defaultSelected = selectData.options?.find(item => item.value === urlValue)
      if (parameter && defaultSelected) {
        state.selectedRowKeys = [defaultSelected]
      }

      let _isVal = []
      if(!selectData.options.mode) {
        _isVal = state.selectedRowKeys[0]?.value
      } else {
        state.selectedRowKeys.forEach(item => {
          if(item.check) _isVal.push(item.value)
        })
      }
      instance.isValue = _isVal
      // console.log('state.selectedRowKeys',state.selectedRowKeys)
      render(createVNode(Forms(selectData)), boxRef.value)
      // console.log('selectRef.value.value',selectRef.value)
      value.value = state.selectedRowKeys
      // selectRef.value.value = state.selectedRowKeys
    }

    const coverData = (data: any) => {
      if (!data || !data[0]) return []
      let CData = []
      for (let i = 0; i < data[1].length; i++) {
        let obj = {
          "key": i,
          "label": data[0][i],
          "value": data[1][i],
          "check": data[2] ? data[2][i] : false
        }
        CData.push(obj)
      }
      return CData
    }

    onMounted(() => {
      instance.proxy.$el.__vueComponent = instance

      instance.setOption = setOption
      console.log(instance)
    })


    let value = ref();
    // if (data.options.mode)

    // const checkValue = (values) => {
    //   let checkOptions = []
    //   values.forEach(item => {
    //     const _check = state.optionData.find(items => items.value == item)
    //     checkOptions.push(_check)
    //   })
    //   state.selectedRowKeys = checkOptions
    // }
    const changeSize = (data: any) => {
      if(!data) return
      if(data == 'small') return { 'fontSize': '12px'}
      if(data == 'default') return { 'fontSize': '14px'}
      if(data == 'large') return { 'fontSize': '16px', 'lineHeight': 2.5}
    }

    const Forms = (data) => () => h(Form, {
      ref: formRef,
    },
      () => h(FormItem, {
        labelCol:{ style: changeSize(data.size) },
        ...(data as FormItemProps)
      },
        () => h(Select, {
          ref: selectRef,
          value: value.value,
          maxTagCount:"responsive",
          onChange: (e: any) => {
            value.value = e
            let values = { target: { value: e } }
            instance.isValue = e
            context.emit('change', values)
          },
          ...(data as SelectProps)
        })
      )
    )

    const VNode = {
      render: () => h('div', {
        id: props.id,
        ref: boxRef,
      },
        () => Forms
      )
    }

    context.emit('finished', () => { return true });
    return () => h(VNode)
  }
}

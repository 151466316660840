import {
  defineComponent,
  reactive,
  SetupContext,
  PropType,
  computed,
  onMounted,
  TransitionGroup,
  onUnmounted,
  getCurrentInstance,
} from 'vue'
import { IProps, IBoardContentItem, IList, IState } from './types'
import './index.less'

// icon图片
const Icon = require('./images/icon.png')

// 轮播器时间间隔
const INTERVAL_TIME = 3000
// pageSize
const PAGE_SIZE = 2

// 翻页step
const CURRENT_STEP = 1

// 第一页
const FIRST_CURRENT_PAGE = 1

// 组件
class RenderComponent {
  // 头部组件
  static Header({ title }: { title: string }) {
    return <div class="board-item__header">{title}</div>
  }

  // info组件
  static BoardInfo({
    boardItem,
    //  date,
  }:
  {
    boardItem: Partial<IBoardContentItem>
    // date: string
  }) {
    const { title, code, btime } = boardItem
    return (
      <div class="board-item__info">
        <div class="left">
          <img class="icon" src={Icon} alt="" />
          <div class="left-info">
            <div class="sub-title">{title}</div>
            <div class="code">{code}</div>
          </div>
        </div>
        <div class="date">{btime ?? ''}</div>
      </div>
    )
  }

  // 进度条组件
  static ProcessBar({ process }: { process: number }) {
    return (
      <div class="custom-process-wrap">
        <div class="custom-process">
          <div class="inner" style={{ width: `${process * 100}%` }}></div>
        </div>
        <div class="process-text">{(Number(process) * 100).toFixed(2)}%</div>
      </div>
    )
  }

  // 内容列表
  static BoardContentItem({ data }: { data: IList[] }) {
    return (
      <TransitionGroup name="board-list">
        {data.map((row: IList) => {
          return (
            <div class="board-content__item" key={row.orderNo}>
              <div class="row">
                <div class="row-label">单号</div>
                <div class="row-value">{row.orderNo}</div>
              </div>
              <div class="row">
                <div class="row-label">款式</div>
                <div class="row-value">{row.style}</div>
              </div>
              <div class="row">
                <div class="row-label">进度</div>
                <RenderComponent.ProcessBar process={row.process} />
              </div>
            </div>
          )
        })}
      </TransitionGroup>
    )
  }
}

export default defineComponent({
  name: 'BoardList',
  props: {
    option: {
      type: Object, // 通过 init 获取来的初始值
    },
    staticData: Array,
  },
  setup(props, { emit }) {
    const instance: any = getCurrentInstance() // 获取组件实体

    const state = reactive<IState>({
      timer: null,
      currentPage: 1,
      boardItemInfo: {},
      currentList: [],
      currentTime: '',
      dateTimer: null,
      option: {
        type: props.option.type,
        dataset: props.option.dataset || [],
      },
    })

    const setOption = data => {
      //获取更新数据 后自有逻辑
      state.option = { ...data }
      // instance.update()
      if (data.dataset.source.length == 0) data.dataset = props.staticData
      // ! 兼容老版本的模式、老版本模式为data、后续采用source
      state.boardItemInfo = data.dataset?.source?.[0] || data.dataset?.data?.[0]
      state.currentList = state.boardItemInfo?.list.slice(0, 2) || []
      hasNextPage.value && registerTimer()
      clearTimer(state.dateTimer)
      state.dateTimer = setInterval(() => {
        getCurrentDate()
      }, 1000)
    }

    // 获取当前时间
    const getCurrentDate = () => {
      const now = new Date()
      const hour = now.getHours().toString().padStart(2, '0')
      const minute = now.getMinutes().toString().padStart(2, '0')
      const second = now.getSeconds().toString().padStart(2, '0')
      state.currentTime = `${hour}:${minute}:${second}`
    }

    // 大于两条有翻页
    const hasNextPage = computed(
      () => state.boardItemInfo.list.length > PAGE_SIZE
    )

    // 移除定时器
    const clearTimer = (timer: number | null): void => {
      if (timer) clearInterval(timer)
    }

    // 切割数据
    const getData = (currentPage: number) => {
      const list = state.boardItemInfo.list
      const total = list.length
      const totalPage = Math.ceil(total / PAGE_SIZE)
      const begin = currentPage > totalPage ? 1 : (currentPage - 1) * 2
      const end = begin + 2
      console.log('getData', state.boardItemInfo.list.slice(begin, end))
      return state.boardItemInfo.list.slice(begin, end)
    }

    // 注册定时器
    const registerTimer = () => {
      const list = state.boardItemInfo.list
      const total = list.length
      const totalPage = Math.ceil(total / PAGE_SIZE)
      clearTimer(state.timer)
      state.timer = setInterval(() => {
        const allowNextPage = state.currentPage < totalPage
        state.currentPage = allowNextPage
          ? state.currentPage + CURRENT_STEP
          : FIRST_CURRENT_PAGE
        state.currentList = getData(state.currentPage)
      }, INTERVAL_TIME)
    }

    const init = () => {
      // 初始化赋值
      instance.proxy.$el.__vueComponent = instance //挂载组件实体
      instance.setOption = setOption //注册setOption
      console.log('props---', props)
      // state.currentList = props.borderItem.list.slice(0, 2)
      // hasNextPage.value && registerTimer()
      // clearTimer(state.dateTimer)
      // state.dateTimer = setInterval(() => {
      //   getCurrentDate()
      // }, 1000)
    }

    onMounted(() => {
      init()
      emit('finished')
    })

    // 销毁组件时、移除定时器、释放内存
    onUnmounted(() => {
      clearTimer(state.timer)
      clearTimer(state.dateTimer)
    })

    return () => (
      <div class="board-item">
        <RenderComponent.Header title={state.boardItemInfo.title} />
        <RenderComponent.BoardInfo
          boardItem={state.boardItemInfo}
          // date={state.currentTime}
        />
        <div class="board-item__content">
          <RenderComponent.BoardContentItem data={state.currentList} />
        </div>
      </div>
    )
  },
})

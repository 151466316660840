String.prototype.format = function (...array) {
  if (array.length == 1 && Array.isArray(array[0])) {
    array = array[0]
  }
  return this.replace(/\{(\d+)\}/g, function (m, i) {
    return array[i]
  })
}

String.prototype.before = function (str) {
  var index = this.lastIndexOf(str)
  return this.substring(0, index)
}
String.prototype.after = function (str) {
  var index = this.lastIndexOf(str)
  return this.substring(index + 1, this.length)
}
String.prototype.splitByArray = function (...array) {
  if (array.length == 1 && Array.isArray(array[0])) {
    array = array[0]
  }
  let temp = this
  array.forEach(item => {
    temp = temp.split(item)
  })
  return temp
}
const data = [
  {
    id: '1',
    bill_no: '21010019',
    customer_order_no: '20216373',
    customer_material_code: '09097',
    material_id: '1354679492269969408',
    material_code: '9877',
    material_name: '\u8fd0\u52a8\u6b3e\u624b\u5957',
    color_code: '001',
    color_name: '001\u519b\u7eff',
    part_name: '\u624b\u8179',
    S__订单量: '0',
    S__累计入库: '0',
    S__本次入库: '0',
    XS__订单量: '200',
    XS__累计入库: '0',
    XS__本次入库: '50'
  }
]
const colums=[
  {
    "id": "1",
    "title": "序号",
    "dataIndex": "iSeqNo",
    "level": 1,
    "width": "180",
    "fixed": false,
    "isSummary": false,
    "summary": "",
    "isShow": true,
    "resizable": true,
    "children": [
      {
        "id": "sub-id2",
        "level": 2,
        "title": "a",
        "dataIndex": "a",
        "width": "80",
        "summary": "",
        "fixed": false,
        "isShow": true,
        "resizable": true
      },
      {
        "id": "sub-id3",
        "level": 2,
        "title": "b",
        "dataIndex": "b",
        "width": "80",
        "summary": "",
        "fixed": false,
        "isShow": true,
        "resizable": true
      }
    ],
    "_type": "colorText"
  },
  {
    "id": "2",
    "title": "订单号",
    "dataIndex": "sOrderNo",
    "level": 1,
    "width": "180",
    "fixed": false,
    "summary": "",
    "isShow": true,
    "resizable": true,
    "children": [
      {
        "id": "sub-id4",
        "level": 2,
        "title": "c",
        "dataIndex": "c",
        "width": "60",
        "summary": "",
        "fixed": false,
        "isShow": true,
        "resizable": true
      },
      {
        "id": "sub-id5",
        "level": 2,
        "title": "d",
        "dataIndex": "d",
        "width": "60",
        "summary": "",
        "fixed": false,
        "isShow": true,
        "resizable": true
      }
    ]
  },
  {
    "id": "3",
    "title": "订单类型",
    "dataIndex": "sOrderTypeName",
    "level": 1,
    "width": 120,
    "fixed": false,
    "isSummary": false,
    "summary": "",
    "isShow": true,
    "resizable": true
  },
  {
    "id": "4",
    "title": "客户",
    "dataIndex": "sCustomerName",
    "level": 1,
    "width": 120,
    "fixed": false,
    "isSummary": false,
    "summary": "",
    "isShow": true,
    "resizable": true
  },
  {
    "id": "5",
    "title": "布号",
    "dataIndex": "sMaterialNo",
    "level": 1,
    "width": 120,
    "fixed": false,
    "isSummary": false,
    "isShow": true,
    "resizable": true
  },
  {
    "id": "6",
    "title": "色号",
    "dataIndex": "sColorNo",
    "level": 1,
    "width": 120,
    "fixed": false,
    "isSummary": false,
    "summary": "",
    "isShow": true,
    "resizable": true
  },
  {
    "id": "vVJ4x",
    "title": "色名",
    "dataIndex": "sColorName",
    "width": "120",
    "summary": "",
    "level": 1,
    "fixed": false,
    "isShow": true,
    "resizable": true
  },
  {
    "id": "7jkYS",
    "title": "订单数量/米",
    "dataIndex": "nPlanQty",
    "width": "120",
    "summary": "",
    "level": 1,
    "fixed": false,
    "isShow": true,
    "resizable": true,
    "isSummary": true
  },
  {
    "id": "XTsAp",
    "title": "研发工艺",
    "dataIndex": "sRouterName",
    "width": "120",
    "summary": "",
    "level": 1,
    "fixed": false,
    "isShow": true,
    "resizable": true
  },
  {
    "id": "ycbmI",
    "title": "工艺路线",
    "dataIndex": "sRouters",
    "width": "250",
    "summary": "",
    "level": 1,
    "fixed": false,
    "isShow": true,
    "resizable": true
  },
  {
    "id": "Mbp1j",
    "title": "变更次数",
    "dataIndex": "nChangeTimes",
    "width": "120",
    "summary": "",
    "level": 1,
    "fixed": false,
    "isShow": true,
    "resizable": true
  },
  {
    "id": "8YsSV",
    "title": "是否替换研发工艺",
    "dataIndex": "bReplaced",
    "width": "240",
    "summary": "",
    "level": 1,
    "fixed": false,
    "isShow": true,
    "resizable": true
  },
  {
    dataIndex: '*__本次入库',
    _type: 'input',
    title: '本次入库',
    width: '150',
    topTitle: '{0}'
  },
  {
    dataIndex: '*__*',
    _type: 'text',
    title: '{1}',
    width: '150',
    topTitle: '{0}'
  },
  {
    "id": "",
    "title": "",
    "dataIndex": "*",
    "width": "240",
    "summary": "",
    "level": 1,
    "fixed": false,
    "isShow": true,
    "resizable": true
  }
]
const colums2 = [
  {
    title: '部位',
    dataIndex: 'part_name',
    isSort: true,
    fixed: false,
    _type: 'text'
  },
  {
    dataIndex: '*__本次入库',
    _type: 'input',
    title: '本次入库',
    width: '150',
    topTitle: '{0}'
  },
  {
    dataIndex: '*__*',
    _type: 'text',
    title: '{1}',
    width: '150',
    topTitle: '{0}'
  },
  {
    title: 'id',
    dataIndex: 'id',
    isHide: true,
    _type: 'text'
  },
  {
    title: '',
    dataIndex: '*',
    isHide: true,
    _type: 'text'
  }
]

// console.log('{0}'.format(...[1, 2]))
// console.log('{0}'.format(...[2]))
// console.log('{1}'.format(...[1, 2]))
// console.log('{3}'.format(...[1, 2]))
// let caption = calcCaption('*', 'A__*', 'A__a')
// console.log(caption)
// caption = calcCaption('{1}', '*__*', 'A__b')
// console.log(caption)
// caption = calcCaption('{1}', '*__*', 'A__c')
// console.log(caption)

/**
 *
 * @param {*} caption
 * @param {*} fieldName
 * @param {*} realFieldName
 */

function calcCaption (caption, fieldName, realFieldName) {
  if (!caption) return caption
  if (
    !caption.includes('*') &&
    !caption.includes('{') &&
    !caption.includes('}')
  ) {
    return caption
  }
  if (fieldName === '*' && caption === '*') {
    return realFieldName
  }

  const xin = '*'
  // 字段名里面最多几个*，则代表string.Format将*替换成{0}...时的最大数
  const iMaxXinCount = fieldName.split(xin).length - 1 // _sFieldName.Where(x => x == xin).Count();
  let i = 0
  while (i < iMaxXinCount && caption.includes(xin)) {
    caption = caption.before(xin) + '{' + i + '}' + caption.after(xin)
    i++
  }
  const a = fieldName.split(xin).filter(x => x)
  const s = realFieldName.splitByArray(a).filter(x => x)
  // console.log(caption, a, s)
  return caption.format(s)
}

function filterDynamicColumns (allColumns, field, allFixedFields) {
  if (field === '*') {
    // 如果仅有一个*列，则表示所有列都是动态创建
    if (allFixedFields.length === 1) {
      return allColumns
    } else {
      // 排除掉已经存在的列
      // 1.首先排除掉固定列已经存在的
      const staticColumns = allFixedFields.filter(x => !x.dataIndex.includes('*'))
      // 排除掉固定列
      let dynamicColumns = allColumns.filter(
        field => !staticColumns.find(item => item.dataIndex === field)
      )
      // 匹配*.x列,缩小*匹配的列范围
      var otherStartColumns = allFixedFields.filter(
        x => x.dataIndex !== '*' && x.dataIndex.includes('*')
      )
      if (otherStartColumns.length) {
        otherStartColumns.forEach(item => {
          const otherDynamicMatchColumns = filterDynamicColumns(
            dynamicColumns,
            item.dataIndex,
            allFixedFields
          )
          dynamicColumns = dynamicColumns.filter(
            x => !otherDynamicMatchColumns.includes(x)
          )
        })
      }
      return dynamicColumns
    }
  } else {
    const sRegexPattern = field
      .replace('.', '\\.')
      .replace('$', '\\$')
      .replace('^', '\\^')
      .replace(/\*/g, '.*')
    const regex = new RegExp(sRegexPattern, 'i')
    let dynamicColumns = allColumns.filter(x => regex.test(x))
    // 首先排除掉固定列已经存在的
    dynamicColumns = dynamicColumns.filter(
      item =>
        !allFixedFields.find(field => field.dataIndex === item && field !== '*')
    )
    return dynamicColumns
  }
}

export function getTableColums (colums, allColumns) {
  const list = []
  const level1ColumnList = {}
  const addedColumn = []
  colums.forEach(item => {
    if (item.dataIndex && item.dataIndex.includes('*')) {
      const dynamicColumns = filterDynamicColumns(allColumns, item.dataIndex, colums)
      const {
        _type,
        id,
        title,
        dataIndex,
        width,
        level,
        fixed,
        isSummary,
        summary,
        isShow,
        resizable,
      } = item
      dynamicColumns.forEach(fieldName => {
        if (addedColumn.includes(fieldName)) {
          return
        }
        addedColumn.push(fieldName)
        const caption = calcCaption(item.title, item.dataIndex, fieldName)
        const caption1 = calcCaption(item.topTitle, item.dataIndex, fieldName) // 二级标题
        if (caption1) {
          let obj = level1ColumnList[caption1]
          if (!obj) {
            obj = {
              children: []
            }
            level1ColumnList[caption1] = obj
            list.push(obj)
          }
          obj.title = caption1
          obj.children.push({
            title: caption||fieldName,
            dataIndex: fieldName,
            _type,
            id,
            level,
            fixed,
            isSummary,
            summary,
            isShow,
            resizable
          })
        } else {
          list.push({
            title: caption||fieldName,
            dataIndex: fieldName,
            width,
            _type,
            id,
            level,
            fixed,
            isSummary,
            summary,
            isShow,
            resizable
          })
        }
      })
    } else if (item.topTitle && !item.topTitle.includes('*')) {
      const t = list.find(x => x.title === item.topTitle)
      if (t) return
      // 筛选出 分组
      const groupList = colums.filter(x => x.topTitle === item.topTitle)
      list.push({
        title: item.topTitle,
        children: groupList
      })
    } else {
      list.push(item)
    }
  })
  return list.filter(item => Object.keys(item).length)
}
// const allColumns = Object.keys(data[0])
// const list = getTableColums(colums, allColumns)
// console.log(JSON.stringify(list))

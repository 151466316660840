import {
  defineComponent,
  reactive,
  computed,
  onMounted,
  TransitionGroup,
  onUnmounted,
  getCurrentInstance,
  PropType
} from 'vue'
import { IList, IState } from './types'
import './index.less'

// 轮播器时间间隔
const INTERVAL_TIME = 3000
// pageSize
const PAGE_SIZE = 4

// 翻页step
const CURRENT_STEP = 1

// 第一页
const FIRST_CURRENT_PAGE = 1

// 组件
class RenderComponent {
  // 进度条组件
  static ProcessBar ({ process }: { process: number }) {
    const processNum = process * 100
    // 百分比大于百分之85% 文字显示在进度条内
    const seat = processNum > 85 ? processNum - 17 : processNum + 1
    // 0 - 60 延期 60 - 90 正常 90 - 100完成
    const color = 'rgba(95,150,251,1),rgba(40,51,75,0)'

    return (
      <div class='custom-process-wrap'>
        <div class='custom-process'>
          <div
            class='inner'
            style={{
              width: `${processNum}%`,
              background: `linear-gradient(270deg,${color})`
            }}
          ></div>
          <div class='process-text' style={{ left: `${seat}%` }}>
            {(Number(process) * 100).toFixed(2)}%
          </div>
        </div>
      </div>
    )
  }
  // 内容列表
  static BoardContentItem ({ data }: { data: IList[] }) {
    return (
      <TransitionGroup name='board-list'>
        {data.map((row: IList, index: number) => {
          return (
            <div class='tailor-board-content__item' key={index}>
              <div class='header'>
                <div class='header-title'>{row.orderNo}</div>
                {row.isUrgent === 1 && <div class='header-emergency'>急</div>}
              </div>
              <div class='material-name'>{row.materialName}</div>
              <RenderComponent.ProcessBar
                process={row.process}
              />
              <div class='plan'>
                <div class='plan-label'>计划/累计</div>
                <div class='plan-value'>{row.planQuantity + '/' + row.cumulativeQuantity}</div>
              </div>
              <div class='plan'>
                <div class='plan-label'>今日完成</div>
                <div class='plan-value'>{row.todayCompleteQuantity}</div>
              </div>
              <div class='plan'>
                <div class='plan-label'>昨日完成</div>
                <div class='plan-value'>{row.yesterdayCompleteQuantity}</div>
              </div>
            </div>
          )
        })}
      </TransitionGroup>
    )
  }
}

export default defineComponent({
  name: 'TailorList',
  props: {
    option: {
      type: Object // 通过 init 获取来的初始值
    },
    staticData: { type: Array as PropType<any>, default: () => [] },
  },
  setup (props, { emit }) {
    const instance: any = getCurrentInstance() // 获取组件实体

    const state = reactive<IState>({
      timer: null,
      currentPage: 1,
      data: [],
      currentList: [],
      option: {
        type: props.option.type,
        dataset: props.option.dataset || []
      }
    })

    const setOption = data => {
      //获取更新数据 后自有逻辑
      state.option = { ...props.option, ...data }
      if (!data.dataset.source.length) data.dataset = props.staticData
       // ! 兼容老版本的模式、老版本模式为data、后续采用source
      state.data = data.dataset?.source || data.dataset?.data
      state.currentList = state.data?.slice(0, 4) || []
      hasNextPage.value && registerTimer()
      console.log(state.currentList, state.data, 'setOption========')
    }

    // 大于两条有翻页
    const hasNextPage = computed(() => state.data.length > PAGE_SIZE)

    // 移除定时器
    const clearTimer = (timer: number | null): void => {
      if (timer) clearInterval(timer)
    }

    // 切割数据
    const getData = (currentPage: number) => {
      const list = state.data
      const total = list.length
      const totalPage = Math.ceil(total / PAGE_SIZE)
      const begin = currentPage > totalPage ? 1 : (currentPage - 1) * 4
      const end = begin + 4
      return state.data.slice(begin, end)
    }

    // 注册定时器
    const registerTimer = () => {
      const list = state.data
      const total = list.length
      const totalPage = Math.ceil(total / PAGE_SIZE)
      clearTimer(state.timer)
      state.timer = setInterval(() => {
        const allowNextPage = state.currentPage < totalPage
        state.currentPage = allowNextPage
          ? state.currentPage + CURRENT_STEP
          : FIRST_CURRENT_PAGE
        state.currentList = getData(state.currentPage)
      }, INTERVAL_TIME)
    }

    const init = () => {
      // 初始化赋值
      instance.proxy.$el.__vueComponent = instance //挂载组件实体
      instance.setOption = setOption //注册setOption
      emit('finished')
    }

    onMounted(() => {
      init()
    })

    // 销毁组件时、移除定时器、释放内存
    onUnmounted(() => {
      clearTimer(state.timer)
    })

    return () => (
      <div class='tailor-board-item'>
        <div class='tailor-board-item__content'>
          <RenderComponent.BoardContentItem data={state.currentList} />
        </div>
      </div>
    )
  }
})

import { r, d, h, a } from "@/units"
import { onMounted, getCurrentInstance, ref, watch, render, nextTick, computed, createVNode } from 'vue'
// TypeScript 3.8 引入了新的语法 -- 「 仅仅导入 / 导出声明 」
// import type 仅仅导入被用于类型注解或声明的声明语句
import type { PropType } from 'vue'
import { IComplexText, ILocationInfo } from '@/types'
import './index.less'

export default ({
  name: 'ComplexText',
  props: {
    option: {
      type: Object as PropType<IComplexText>,  // 通过 init 获取来的初始值
    },
    staticData: Array,
    locationInfo: Object as PropType<ILocationInfo>
  },
  setup(props, context) {
    const instance: any = getCurrentInstance() // 获取组件实体

    const textRef = ref<any | null>(null)
    const state = {
      option: {
        type: props.option.type,
        dataset: props.option.dataset || [],
      }
    }
    const setOption = (data, a) => { //获取更新数据 后自有逻辑
      if(a) {
        state.option = {...state.option, ...data}
      } else {
        state.option = { ...data }
      }
      
      
      
      // textRef.value.__vueComponent.props.option = { ...data }
      instance.update()
      if (data.dataset.source.length == 0) data.dataset = props.staticData
      // console.log('data.dataset', data)
      render(
        h('div',
          {
            class: 'box-items',
            style: {
              width: '100%',
              // lineHeight: `${props.locationInfo?.height}px`
              // verticalAlign: 'text-bottom'
              display: 'flex',
              alignItems: 'center'
            }
          },
          [
            h('span', {
              class: 'new',
              style: {
                float: 'left',
                width: `${data.lableTitle?.lableWidth}px`,
                fontSize: `${data.lableTitle?.fontSize}px`,
                fontFamily: data.lableTitle?.fontFamily,
                textAlign: data.lableTitle?.fontAlign,
                fontWeight: data.lableTitle?.fontWeight,
                fontStyle: data.lableTitle?.fontStyle,
                color: data.lableTitle?.color,
              }
            }, data.dataset?.source[0][0]),
            h('span', {
              class: 'text-item',
              style: {
                float: 'left',
                width: `${data.generalTitle?.lableWidth}px`,
                fontSize: `${data.generalTitle?.fontSize}px`,
                fontFamily: data.generalTitle?.fontFamily,
                textAlign: data.generalTitle?.fontAlign,
                fontWeight: data.generalTitle?.fontWeight,
                fontStyle: data.generalTitle?.fontStyle,
                color: data.generalTitle?.color,
              }
            }, data.dataset?.source[1][0]),
            h('span', {
              style: {
                float: 'left',
                width: `${data.suffixTitle?.lableWidth}px`,
                fontSize: `${data.suffixTitle?.fontSize}px`,
                fontFamily: data.suffixTitle?.fontFamily,
                textAlign: data.suffixTitle?.fontAlign,
                fontWeight: data.suffixTitle?.fontWeight,
                fontStyle: data.suffixTitle?.fontStyle,
                color: data.suffixTitle?.color,
                alignSelf: 'flex-end'
              }
            }, data.dataset?.source[2][0])


            // h('span',{
            //   float: 'left'}, data.dataset?.source[1][0]),

            // h('span', {
            //   class: 'new',
            //   style: {
            //     float: 'left',
            //     fontSize: `${props.option.suffixTitle.fontSize}px`,
            //     fontFamily: props.option.suffixTitle.fontFamily,
            //     textAlign: props.option.suffixTitle.fontAlign,
            //     fontWeight: props.option.suffixTitle.fontWeight,
            //     fontStyle: props.option.suffixTitle.fontStyle,
            //     color: props.option.suffixTitle.color,
            //   }
          ])
        , textRef.value) //重新渲染
    }
    onMounted(() => {

      // document.documentElement.style.setProperty('--column-width', document.querySelector('.text-item')?.clientHeight / 2 + 'px');
      instance.proxy.$el.__vueComponent = instance //挂载组件实体
      instance.setOption = setOption  //注册setOption

      // const resizeObserver = new ResizeObserver(entries => {
      //   document.documentElement.style.setProperty('--column-width', document.querySelector('.text-item')?.clientHeight / 2 + 'px')
      // });
      // resizeObserver.observe(document.querySelector('.chartNum'))
    })
    const VNode = { //虚拟DOM，结构更具需要设计
      render: () => h('div', {
        class: 'chartNum',
        ref: textRef,
        // style: {
        //   fontSize: `${props.option.generalTitle.fontSize}px`,
        //   fontFamily: props.option.generalTitle.fontFamily,
        //   textAlign: props.option.generalTitle.fontAlign,
        //   fontWeight: props.option.generalTitle.fontWeight,
        //   fontStyle: props.option.generalTitle.fontStyle,
        //   color: props.option.generalTitle.color,
        // }
      },
        // h('span',state.option.dataset.source)
      )
    }

    context.emit('finished', () => { return true });
    return () => createVNode(VNode)
  },
})
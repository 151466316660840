import {
  ComponentInternalInstance, computed, createVNode,
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive, ref, render,
} from 'vue'
import StyleInfoItem from './components/StyleInfoItem.vue'
import { h } from '@/units'
import './index.less'
import { Carousel } from 'ant-design-vue'
import { options } from './data'
import router from '@/router'
import { IStyleInfoItem } from '#/Business/LanSiYu/StyleInfoCarousel/types'

/**
 * @description: instance实例类型
 */
interface InstanceType extends ComponentInternalInstance {
  setOption: Function
}

/**
 * @description: state类型
 */
interface IState {
  option: Record<string, any>
  carouselData: any
  autoplaySpeed: number
}

export default defineComponent({
  name: 'StyleInfoCarousel',
  props: {
    option: {
      type: Object, // 通过 init 获取来的初始值
    },
    staticData: Object,
  },

  setup(props, { emit }) {
    const instance = getCurrentInstance() as InstanceType // 获取组件实体
    const state = reactive<IState>({
      option: {
        type: props.option.type,
        dataset: props.option.dataset || [],
      },
      carouselData: [],
      autoplaySpeed: 10 * 1000
    })

    const isPreview = router.currentRoute.value.name === 'preview'

    const setOption = data => {
      //获取更新数据 后自有逻辑
      state.option = { ...props.option, ...data }
      if (!data.dataset?.source?.length) data.dataset = props.staticData
      getCarouselData(data.dataset.source)
    }

    const to2DArray = (data: IStyleInfoItem[], size: number) => {
      const newArray = []
      let _index = 0
      data?.forEach((item) => {
        if (newArray[_index]?.length >= size) {
          _index += 1
        }
        !newArray[_index] && (newArray[_index] = [])
        newArray[_index].push(item)
      })
      return newArray
    }

    const getCarouselData = (data: IStyleInfoItem[]) => {
      state.carouselData = to2DArray(data, 3)
    }


    const CarouselItem = (data) => (
      <div class="carousel-item">
        {
          data.map(item => <StyleInfoItem styleInfo={item}/>)
        }
      </div>
    )

    const StyleInfoCarousel = () => h(Carousel, {
        autoplay: isPreview,
        dots: false,
        dotPosition: 'bottom',
        autoplaySpeed: state.autoplaySpeed,
      }, state.carouselData.map(arr => h(CarouselItem(arr)))
    )

    const init = () => {
      // 初始化赋值
      instance.proxy.$el.__vueComponent = instance //挂载组件实体
      instance.setOption = setOption //注册setOption
      console.log('props---', props)
      emit('finished')
    }

    onMounted(() => {
      init()
    })

    return () => <div class="style-info-box"><StyleInfoCarousel/></div>
  },
})

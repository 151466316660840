import {
  TransitionGroup,
  computed,
  defineComponent,
  reactive,
  ref,
  getCurrentInstance,
  onMounted,
  PropType,
  onUnmounted
} from 'vue'
import { Row } from 'ant-design-vue'
import './index.less'
import { IObject } from '@/types'

interface IOptions {
  type: string,
  dataset: Array<any>
}

interface IList {
  code: string
  status: string
  bSend: number
}

interface IState {
  timer: any,
  itemName: string,
  transferPlatformNumber: number | string,
  option: IOptions,
  plateFormInfo: IObject
  currentList: IList[],
  currentPage: number
}

// 轮播器时间间隔
const INTERVAL_TIME = 3000
// pageSize
const PAGE_SIZE = 6

// 翻页step
const CURRENT_STEP = 1

// 第一页
const FIRST_CURRENT_PAGE = 1

export default defineComponent({
  name: 'PlatformList',
  props: {
    option: {
      type: Object, // 通过 init 获取来的初始值
    },
    staticData: { type: Array as PropType<any>, default: () => [] },
  },
  setup(props, { emit }) {
    const instance: any = getCurrentInstance() // 获取组件实体
    const state = reactive<IState>({
      option: {
        type: props.option.type,
        dataset: props.option.dataset || [],
      },
      itemName: 'C01班',
      transferPlatformNumber: 0,
      plateFormInfo: {},
      currentList: [],
      currentPage: 1,
      timer: null
    })

    const plateFormNumber = computed(() => state.plateFormInfo.list?.length || 0)
    // 大于两条有翻页
    const hasNextPage = computed(() => plateFormNumber.value > PAGE_SIZE)


    const setOption = (data) => {
      //获取更新数据 后自有逻辑
      state.option = { ...props.option, ...data }
      if (data.dataset.source.length == 0) data.dataset = props.staticData
      // ! 兼容老版本的模式、老版本模式为data、后续采用source
      state.plateFormInfo = data.dataset.source?.[0] || data.dataset?.data?.[0]

      state.currentList = state.plateFormInfo?.list.slice(0, PAGE_SIZE) || []
      state.itemName = state.plateFormInfo.name
      state.transferPlatformNumber = state.plateFormInfo.transferPlatformNumber
      // state.itemName = data.dataset?.data[0]?.name || 'C01班'
      hasNextPage.value && registerTimer()
    }

    // 移除定时器
    const clearTimer = (timer: number | null): void => {
      if (timer) clearInterval(timer)
    }

    // 切割数据
    const getData = (currentPage: number) => {
      const list = state.plateFormInfo.list
      const total = list.length
      const totalPage = Math.ceil(total / PAGE_SIZE)
      const begin = currentPage > totalPage ? 1 : (currentPage - 1) * PAGE_SIZE
      const end = begin + PAGE_SIZE
      return state.plateFormInfo.list.slice(begin, end)
    }

    // 注册定时器
    const registerTimer = () => {
      const list = state.plateFormInfo.list
      const total = list.length
      const totalPage = Math.ceil(total / PAGE_SIZE)
      clearTimer(state.timer)
      state.timer = setInterval(() => {
        const allowNextPage = state.currentPage < totalPage
        state.currentPage = allowNextPage
          ? state.currentPage + CURRENT_STEP
          : FIRST_CURRENT_PAGE
        state.currentList = getData(state.currentPage)
      }, INTERVAL_TIME)
    }

    onMounted(() => {
      instance.proxy.$el.__vueComponent = instance //挂载组件实体
      instance.setOption = setOption //注册setOption

      emit('finished')
    })

    // 销毁组件时、移除定时器、释放内存
    onUnmounted(() => {
      clearTimer(state.timer)
    })


    const CardHeader = () => (
      <div class="platform-card-header">
        <div class="header-logo"/>
        <div class="header-text">{state.itemName}</div>
      </div>
    )

    const CardBody = () => (
      <div class="platform-card-body">
        <TransitionGroup name="card-body">
          {state.currentList.map((item: IList) => (
            <div class="body-item" key={item.code}>
              <div class={['body-item-content', item.status == '1' ? 'active' : '']}>
                <div class="body-item-gutter"/>
                {/* <div class="body-item-text">{item.code}</div> */}
                <div class={['body-item-text', item?.bSend === 1 ? 'is-b-send' : '']}>
                  {item.code}
                </div>
              </div>
            </div>
          ))}
        </TransitionGroup>
      </div>
    )

    const CardFooter = () => (
      <div class="platform-card-footer">
        <div class="text">待转运地台数</div>
        <div class={'platform-number'}>{state.transferPlatformNumber}</div>
      </div>
    )

    return () => (
      <div class="platform-card">
        <CardHeader/>
        <CardBody/>
        <CardFooter/>
      </div>
    )

  },
})

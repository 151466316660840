import { r, d, h, a } from "@/units"
import { number } from "echarts"
import { onMounted, getCurrentInstance, ref, render, createVNode, TransitionGroup, nextTick } from 'vue'
import type { PropType } from 'vue'
import { IBaseText } from '@/types'
import './index.less'

export default ({
  name: 'IWatch',
  props: {
    option: {
      type: Object as PropType<IBaseText>,  // 通过 init 获取来的初始值
    },
    staticData: Array
  },
  setup(props, context) {
    const instance: any = getCurrentInstance()
    const boxRefs = ref<any | null>(null)
    const aNumsRef = ref<any | null>(null)
    const state = {
      option: {
        type: props.option.type,
        dataset: props.option.dataset
      },
    }

    const getRandomNum = (n, total) => {
      const getString = (ob) => {
        if (total - preTotal != 0) {
          let nb: string | number = total - preTotal
          nb > 0 ? nb = '<span class="numred"><span class="numplus">+</span>' + nb + '</span>' : nb = '<span class="numgreen">' + nb + '</span>'
          res.push(nb);
        } //最后将剩下的金额添加到数组中
        return res;
      }
      if (typeof total != 'number') return
      var res = [];   //最后返回的数组
      var range = total;  //生成随机金额的范围
      var preTotal = 0;   //已经生成的金额的和
      for (var i = 0; i < (n - 1); i++) {
        var item = Math.ceil(Math.random() * (range / 2));
        getString(item);
        range -= item;  //从范围内减去已经生成的金额
        preTotal += item;   //将已经生成的金额进行累加
      }

      getString(total - preTotal) //最后将剩下的金额添加到数组中
      return res;
    }

    const setOption = (data, a) => {
      
      render(Nums([]), boxRefs.value)
      state.option = { ...data }
      boxRefs.value.__vueComponent.props.option = { ...data }
      instance.update()
      nextTick(() => {
        render(Nums(getRandomNum(Math.floor(Math.random() * (10 - 3 + 1) + 3), data.dataset.source)), boxRefs.value)
      })
    }
    onMounted(() => {
      instance.proxy.$el.__vueComponent = instance
      instance.setOption = setOption
    })  
    const Nums = (data = []) =>
      h('ul', {
        ref: aNumsRef,
        class: 'watchList',
        key: props.id,
        tag: 'ul'
      },
        [data.map((item, index) => {
          return h('li',{
            innerHTML:item,
            style: {
              fontSize: `${props.option.generalTitle?.fontSize || 48}px`,
            }
          },'')
        })]
      )

    const VNode = {
      render: () => h('div', {
        id: props.id,
        ref: boxRefs,
        style: { width: '100%', height: '100%' }
      },
        // () => Nums
      )
    }


    context.emit('finished', () => { return true });
    return () => h(VNode)
  },
})

import { r, d, h, a } from "@/units"
import { onMounted, getCurrentInstance, ref, reactive, render } from 'vue'
import type { PropType } from 'vue'
import { TabsProps, TabPaneProps } from 'ant-design-vue/lib/tabs'
import { Tabs, TabPane } from 'ant-design-vue'
import './index.less'
export default {
  name: "tabs",
  props: {
    option: {
      type: Object as PropType<TabPaneProps>,
      dataset: Object,
      tabBarStyle: Object,
      activeKey: String
    },
    staticData: Object,
    id: String,
  },
  emits: ['finished', 'change'],
  setup(props, context) {
    const instance: any = getCurrentInstance()
    const boxRef = ref<any | null>(null)
    const tabsRef = ref<any | null>(null)


    const state = reactive({
      option: {
        type: props.option.type,
        dataset: props.option.dataset || [],
        activeKey: props.option.activeKey || "1",
        tabBarStyle: props.option.tabBarStyle || {},
        options: props.option.options || [],
        enableExtraParams: props.option.enableExtraParams || false,
        tabType: props.option.tabType || 'line'
      }
    })
    // mock数据转换成Array<Object>
    const convertStaticData = (data) => {
      if (!data || !data.length) return []
      const result = []
      const [labels, values, checkedList, extraParamList] = data
      labels.forEach((item, idx) => {
        const _row = {
          key: idx,
          label: item,
          value: values[idx],
          checked: checkedList[idx],
          extraParams: extraParamList[idx]
        }
        result.push(_row)
      })
      return result
    }

    const setOption = (data, isAppend) => { //分组关键字
      state.option = { ...data, ...state.option }
      boxRef.value.__vueComponent.props.option = { ...data }
      state.option.options = data?.options?.length ? data.options : convertStaticData(props.staticData.source)
      state.option.activeKey = data.activeKey
      state.option.enableExtraParams = data.enableExtraParams
      state.option.tabType = data.tabType
      console.log('state.option--', state.option)
      instance.update()
      // instance.isValue = {
      //   value: data.activeKey
      // }
      if (instance.isValue) {
        instance.isValue.value = data.activeKey
      } else {
        instance['isValue'] = {
          value: data.activeKey
        }
      }

      // instance.isValue = data.activeKey
      render(h(renderTabs(data)), boxRef.value)
    }


    onMounted(() => {
      instance.proxy.$el.__vueComponent = instance
      instance.setOption = setOption
      console.log('instance', instance)
    })

    const renderTabs = (data) => {
      return h(Tabs, {
        ref: tabsRef,
        tabBarGutter: 30,
        type: state.option.tabType,
        activeKey: state.option.activeKey || "1",
        tabBarStyle: state.option.tabBarStyle,
        onChange: (e: any) => {
          instance.isValue.value = e
          // instance.isValue = e
          state.option.activeKey = e
          setOption({ ...state.option, 'activeKey': e }, false)
          const targetTab = state.option.options.find(item => item.value === e)
          context.emit('change', targetTab)
        },
        ...(data as TabsProps)
      },
        {
          default: () => state.option.options?.map((item) => {
            return h(TabPane, {
              key: item.value,
            }, {
              tab: () => h('div', { class: 'tab-btn-title' }, [
                item.label,
                // 如果是string或者是number的话、表示显示的是角标功能
                // 是否开启额外参数功能
                state.option.enableExtraParams && ['string', 'number'].includes(typeof item.extraParams)
                  ? h('span', { class: 'sub-mark' }, item.extraParams)
                  : null
              ]),
              default: () => ('')
            })
          })
        }
      )
    }

    const VNode = {
      render: () => h('div', {
        id: props.id,
        ref: boxRef,
      },
        {
          default: () => renderTabs
        }
      )
    }

    context.emit('finished', () => { return true });
    return () => h(VNode)
  }
}

import { r, d, h, a } from '@/units'
import { onMounted, getCurrentInstance, ref, watch, render, nextTick, computed, createVNode, reactive } from 'vue'
// TypeScript 3.8 引入了新的语法 -- 「 仅仅导入 / 导出声明 」
// import type 仅仅导入被用于类型注解或声明的声明语句
import type { PropType } from 'vue'
import { IBaseText } from '@/types'
import { intervalOptions, weeks } from '@/utils/constance'

export default ({
  name: 'ITime',
  props: {
    option: {
      type: Object as PropType<IBaseText>,
    },
  },
  setup(props, context) {
    const instance: any = getCurrentInstance()
    const timeRef = ref<any | null>(null)

    const timeDateText = reactive({
      timeText: '',
      dateText: '',
      weekText: '',
    })

    const state = {
      option: {
        type: props.option.type,
        dataset: props.option.dataset
      },
    }

    const setOption = (e, a) => {
      state.option = { ...e }
      timeRef.value.__vueComponent.props.option = { ...e }
      instance.update()
      timeRef.value.__vueComponent.proxy.$forceUpdate()
    }

    const justifyContent = computed(() => {
      const { fontAlign } = props.option.generalTitle
      return fontAlign === 'center' ? 'center' : fontAlign === 'right' ? 'flex-end' : 'flex-start'
    })

    const timeAdd0 = (m) => {
      if (m.length < 2) {
        m = '0' + m;
      }
      return m
    }

    const showTime = () => {
      const data = new Date()
      const year = data.getFullYear()
      const month = timeAdd0((data.getMonth() + 1).toString())
      const day = timeAdd0((data.getDate()).toString())
      const week = data.getDay()
      const hour = timeAdd0(data.getHours().toString())
      const minute = timeAdd0(data.getMinutes().toString())
      const second = timeAdd0(data.getSeconds().toString())
      const time = `${year}-${month}-${day} ${hour}:${minute}:${second}`

      const { interval } = props.option.timeConfig
      let dateText
      if (interval === '2') {
        dateText = `${year}年${month}月${day}日`
      } else {
        const intervalText = intervalOptions.find(item => item.value === interval).label
        dateText = `${year}${intervalText}${month}${intervalText}${day}`
      }
      const timeText = `${hour}:${minute}:${second}`
      const weekText = ` ${weeks[week]}`
      return { time, dateText, timeText, weekText }
    }

    onMounted(() => {
      instance.proxy.$el.__vueComponent = instance
      instance.setOption = setOption
      setInterval(() => {
        const { time, dateText, timeText, weekText } = showTime()
        // timeRef.value.innerHTML = time
        timeDateText.timeText = timeText
        timeDateText.dateText = dateText
        timeDateText.weekText = weekText
      }, 500)
    })

    const VTimeDateText = (content) => {
      return createVNode('div', {
        style: {
          overFlow: 'hidden',
          whiteSpace: 'nowrap',
          marginRight: '5px'
        }
      }, content)
    }

    const VNode = {
      render: () => h('div', {
          ref: timeRef,
          style: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: justifyContent.value,
            fontSize: `${props.option.generalTitle.fontSize}px`,
            fontFamily: props.option.generalTitle.fontFamily,
            textAlign: props.option.generalTitle.fontAlign,
            color: props.option.generalTitle.color,
          }
        },
        [
          // VDateText()
          props.option.timeConfig.showContent.map(item => {
            const param = `${item}Text`
            return VTimeDateText(timeDateText[param])
          })
        ]
      )
    }

    context.emit('finished', () => {
      return true
    })
    return () => createVNode(VNode)
  },
})

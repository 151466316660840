import { r, d, h, a } from "@/units"
import { onMounted, getCurrentInstance, ref, watch, render, nextTick, computed, createVNode } from 'vue'
// TypeScript 3.8 引入了新的语法 -- 「 仅仅导入 / 导出声明 」
// import type 仅仅导入被用于类型注解或声明的声明语句
import type { PropType } from 'vue'
import { IBaseText } from '@/types'
import './index.less'

export default ({
  name: 'Liquid',
  props: {
    option: {
      type: Object,
    },
    staticData: Array
  },
  setup(props, context) {
    const instance: any = getCurrentInstance()
    const flapRef = ref<any | null>(null)
    const numberItem = ref<any | null>(null)
    const state = {
      option: {
        type: props.option.type,
        dataset: props.option.dataset
      },
      orderNum: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }

    const setNumberTransform = () => {

      const elArray = Array.prototype.slice.call(numberItem.value.childNodes)
      const numberItems = elArray.filter((item) => item.className == 'number-itemss') // 拿到数字的ref，计算元素数量
      const numberArr = state.orderNum.filter(item => !isNaN(item))
      // 结合CSS 对数字字符进行滚动,显示订单数量
      for (let index = 0; index < numberItems.length; index++) {
        const elem = numberItems[index]
        elem.firstChild.firstChild.style.transform = `translate(-50%, -${numberArr[index] * 10}%)`
      }
    }
    const toOrderNum = (num, len) => {
      // console.log('num--------------------------', num)
      num = num.toString()
      // 把数变成字符串
      if (num.length < len) {
        num = '0' + num // 如未满位数，添加"0"补位
        toOrderNum(num, len) // 递归添加"0"补位
      } else {
        // 数中加入逗号
        state.orderNum = num.split('') // 将其便变成数据，渲染至滚动数组
      }
      nextTick(() => {
        setNumberTransform()
      })

    }
    const setOption = (data, a) => {
      // console.log('setOption----------props.staticData.source', props.staticData.source)
      for (let nblen in data.numberLength) {
        state.orderNum[nblen] = 0
      }
      if(data.dataset&&!data.dataset.source){
        data.dataset.source=[]
      }
      if (data.dataset?.source.length == 0) data.dataset = props.staticData
      // data.dataset = props.staticData
      state.option = { ...data }
      flapRef.value.__vueComponent.props.option = { ...data }
      instance.update()
      flapRef.value.__vueComponent.proxy.$forceUpdate()
      let _num = typeof data.dataset.source[1] == 'object' ? data.dataset.source[1] : data.dataset.source
      toOrderNum(_num, data.numberLength)
    }
    onMounted(() => {
      document.documentElement.style.setProperty('--column-widths', document.querySelector('.number-itemss').clientHeight / 1.25 + 'px');
      instance.proxy.$el.__vueComponent = instance
      instance.setOption = setOption

      const resizeObserver = new ResizeObserver(entries => {
        document.documentElement.style.setProperty('--column-widths', document.querySelector('.number-itemss')?.clientHeight / 1.25 + 'px')
      });
      resizeObserver.observe(document.querySelector('.chartNumss'))

      context.emit('finished', () => { return true });
    })

    // value.__vueComponent.vnode.el
    const getAlign = (e) => {
      if (e == 'left') {
        return '0%'
      } else if (e == 'right') {
        return '100%'
      } else {
        return '50%'
      }
    }
    const getBorder = (e) => {
      if (e) {
        return '1px solid rgba(221,221,221,1)'
      } else {
        return '0'
      }
    }

    const Nums = () => [
      h('div', {
        class: 'box-itemss',
        ref: numberItem
      },
        state.orderNum.map((item, index) => {
          return h('div', {
            class: { 'number-itemss': !isNaN(item) },
            key: index,
            style: {
              color: props.option.generalTitle?.color,
              // border: getBorder(props.option.border?.show)
            }
          },
            [
              !isNaN(item) ? h('span', {
                style: {
                  fontSize: `${props.option.generalTitle?.fontSize}px`,
                }
              },
                h('i', {
                  style: {
                    // fontSize: `${props.option.generalTitle?.fontSize}px`,
                    // fontFamily: props.option.generalTitle?.fontFamily ? props.option.generalTitle?.fontFamily : '',
                    left: getAlign(props.option.generalTitle?.fontAlign),
                  }
                },
                  '0123456789'
                )
              )
                :
                h('span', {
                  class: 'comma'
                },
                  item
                )
            ]
          )
        })
      )
    ]

    const VNode = {
      render: () => h('div', {
        class: 'chartNumss',
        ref: flapRef,
      },
        [
          h('div', {
            class: 'box-itemss',
            ref: numberItem
          },
            state.orderNum.map((item, index) => {
              return h('div', {
                class: { 'number-itemss': !isNaN(item) },
                key: index,
                style: {
                  color: props.option.generalTitle?.color,
                  // border: getBorder(props.option.border?.show)
                }
              },
                [
                  !isNaN(item) ? h('span', {
                    style: {
                      fontSize: `${props.option.generalTitle?.fontSize}px`,
                    }
                  },
                    h('i', {
                      style: {
                        // fontSize: `${props.option.generalTitle?.fontSize}px`,
                        // fontFamily: props.option.generalTitle?.fontFamily ? props.option.generalTitle?.fontFamily : '',
                        left: getAlign(props.option.generalTitle?.fontAlign),
                      }
                    },
                      '0123456789'
                    )
                  )
                    :
                    h('span', {
                      class: 'comma'
                    },
                      item
                    )
                ]
              )
            })
          )
        ]
      )
    }

    context.emit('finished', () => { return true });
    return () => h(VNode)
  },
})

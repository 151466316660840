import { r, d, h, a } from "@/units"
import { onMounted, getCurrentInstance, ref, watch, render, nextTick, computed, createVNode, TransitionGroup } from 'vue'
import type { PropType } from 'vue'
import { FormItemProps, FormItemInstance } from 'ant-design-vue/lib/form'
import { RadioGroupProps } from 'ant-design-vue/lib/radio'
import { RadioGroup, Radio, Form, FormItem } from 'ant-design-vue'
import _ from 'lodash'
// import { IFormOption  } from '@/store/modules/editor'

import './index.less'
export default {
  name: "IRadio",
  props: {
    option: {
      type: Object as PropType<FormItemProps>,
      dataset: Object
    },
    id: String,
    staticData: Object
  },
  emits: ['finished', 'change'],
  setup(props, context) {
    const instance: any = getCurrentInstance()
    const boxRef = ref<any | null>(null)
    const radioRef = ref<any | null>(null)
    const formRef = ref<any | null>(null)
    const state = {
      option: {
        type: props.option.type
      },
      selectedRowKeys: [],
      optionData: []
    }
    const setOption = (data, isAppend) => { //分组关键字
      state.option = { ...data }
      // boxRef.value.__vueComponent.props.option = { ...data }
      /* 删除 data 中的 dataset 对象 */

      const selectData: any = _.cloneDeep(data)
      delete selectData.dataset
      // console.log('[]??????????', coverData(props.staticData.source)) // 不是dataset 
      selectData.options = coverData(props.staticData.source)
      instance.update()

      state.optionData = selectData.options
      state.selectedRowKeys = selectData.options?.filter(item => !!item.check) || []

      let _isVal = ""
      state.selectedRowKeys.forEach(item => {
        if (item.check) _isVal = item.value
      })
      instance.isValue = _isVal

      render(createVNode(Forms(selectData)), boxRef.value)

      // console.log(Object.keys([...selectData]) in FormItemProps)
      value.value = _isVal
      // new removeInitData(boxRef.value)

      // console.log(FormItemProps.hasOwnProperty(...selectData))
    }
    const coverData = (data: any) => {
      if (!data || !data[0]) return
      let CData = []
      for (let i = 0; i < data[1].length; i++) {
        let obj = {
          "key": i,
          "label": data[0][i],
          "value": data[1][i],
          "check": data[2] ? data[2][i] : false
        }
        CData.push(obj)
      }
      return CData
    }

    onMounted(() => {
      instance.proxy.$el.__vueComponent = instance
      instance.setOption = setOption
      console.log(instance)
    })

    const removeInitData = (el: any) => {
      if (el.childNodes.length > 1) el.removeChild(el.childNodes[0])
    }

    const value = ref<string>('');

    const changeSize = (data: any) => {
      if(!data) return
      if(data == 'small') return { 'fontSize': '12px'}
      if(data == 'default') return { 'fontSize': '14px'}
      if(data == 'large') return { 'fontSize': '16px', 'lineHeight': 2.5}
    }

    const Forms = (data) => () => h(Form, {
      ref: formRef,
    },
      () => h(FormItem, {
        labelCol:{ style: changeSize(data.size) },
        ...(data as FormItemProps)
      },
        () => h(RadioGroup, {
          style: {
            // padding:`0 ${data.buttonMargin}px`
            display: 'flex',
            justifyContent: 'space-between'
          },
          ref: radioRef,
          value: value.value,
          onchange: (e) => {
            value.value = e.target._value
            let values = { target: { value: e.target._value } }
            instance.isValue = e.target._value
            context.emit('change', values);
          },
          ...(data as RadioGroupProps)
        })
      )
    )

    const VNode = {
      render: () => h('div', {
        id: props.id,
        ref: boxRef,
      },
        () => Forms
      )
    }

    context.emit('finished', () => { return true });
    return () => h(VNode)
  }
}

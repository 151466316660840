import { r, d, h, a } from "@/units"
import { onMounted, getCurrentInstance, ref, watch, render, nextTick, computed, createVNode, TransitionGroup } from 'vue'
import type { PropType } from 'vue'
import { FormItemProps, FormItemInstance } from 'ant-design-vue/lib/form'
import { ButtonProps } from 'ant-design-vue/lib/button'
import { Button, Form, FormItem } from 'ant-design-vue'
import './index.less'
import _ from 'lodash'
// import { IFormOption  } from '@/store/modules/editor'

export default {
  name: "OutPrint",
  props: {
    option: {
      type: Object as PropType<FormItemProps>
    }
  },
  emits: ['finished','change'],
  setup(props, context) {
    const instance: any = getCurrentInstance()
    const boxRef = ref<any | null>(null)
    const buttonRef = ref<any | null>(null)
    const formRef = ref<any | null>(null)
    const state = {
      option: {
        type: props.option.type
      }
    }
    const setOption = (data, isAppend) => { //分组关键字
      state.option = { ...data }
      boxRef.value.__vueComponent.props.option = { ...data }
      /* 删除 data 中的 dataset 对象 */


      const selectData: any = _.cloneDeep(data)
      delete selectData.dataset
      instance.update()

      render(createVNode(Forms(selectData)), boxRef.value)
    }
   
    onMounted(() => {
      instance.proxy.$el.__vueComponent = instance
      
      instance.setOption = setOption
      console.log(instance)
    })

    const Forms = (data) => h(Form, {
      ref: formRef,
    },
      () => h(FormItem, {
        // ...(data as FormItemProps)
      },
        () => h(Button, {
          ref: buttonRef,
          type: 'primary',
          onClick: (e: any) => {
            context.emit('change', e);
          },
          size: data.size
          // ...(data as ButtonProps)
        }, () => '打印')
      )
    )

    const VNode = {
      render: () => h('div', {
        id: props.id,
        ref: boxRef,
      },
        () => Forms
      )
    }

    context.emit('finished', () => { return true });
    return () => h(VNode)
  }
}

import { r, d, h, a } from "@/units"
import { onMounted, getCurrentInstance, ref, watch, render, nextTick, computed, createVNode } from 'vue'
// TypeScript 3.8 引入了新的语法 -- 「 仅仅导入 / 导出声明 」
// import type 仅仅导入被用于类型注解或声明的声明语句
import type { PropType } from 'vue'
import { IBaseText } from '@/types'

export default ({
  name: 'CarouselText',
  props: {
    option: {
      type: Object as PropType<IBaseText>,  // 通过 init 获取来的初始值
    },
    staticData: Array
  },
  setup(props, context) {
    const instance: any = getCurrentInstance() // 获取组件实体
    const textRef = ref<any | null>(null)
    const state = {
      option: {
        type: props.option.type,
        dataset: props.option.dataset || [],
      }
    }
    const setOption = (data, a) => { //获取更新数据 后自有逻辑
      state.option = { ...data }
      textRef.value.__vueComponent.props.option = { ...data }
      // instance.update()
      if (data.dataset.source.length == 0) data.dataset = props.staticData
      console.log('data.dataset.source',data.dataset)

      render(h('marquee', data.dataset?.source[1][0]), textRef.value) //重新渲染
    }
    onMounted(() => {
      instance.proxy.$el.__vueComponent = instance //挂载组件实体
      instance.setOption = setOption  //注册setOption
    })
    const VNode = { //虚拟DOM，结构更具需要设计
      render: () => h('div', {
        ref: textRef,
        style: {
          fontSize: `${props.option.generalTitle.fontSize}px`,
          fontFamily: props.option.generalTitle.fontFamily,
          textAlign: props.option.generalTitle.fontAlign,
          fontWeight: props.option.generalTitle.fontWeight,
          fontStyle: props.option.generalTitle.fontStyle,
          color: props.option.generalTitle.color,
        }
      },
      // h('span',state.option.dataset.source)
      )
    }

    context.emit('finished', () => { return true });
    return () => createVNode(VNode)
  },
})
;
import { r, d, h, a } from "@/units"
import { onMounted, getCurrentInstance, ref, watch, render, nextTick, computed, createVNode } from 'vue'
import { DescriptionsProps, DescriptionsItemProps } from 'ant-design-vue/lib/descriptions'
import { Descriptions, DescriptionsItem } from 'ant-design-vue'

import type { PropType } from 'vue'
import _, { debounce, forEach } from 'lodash'

import './index.less'

type Inner = Array<Record<string, any>> | undefined

type Diff = Array<Inner[]> | undefined

export default {
  name: "Descriptions",
  props: {
    option: {
      type: Object as PropType<DescriptionsProps>,
      dataset: Object
    },
    id: String,
    staticData: Object
  },
  emits: ['finished', 'change'],
  setup(props, context) {
    const instance: any = getCurrentInstance()
    const boxRef = ref<any | null>(null)
    const descriptionsItemRef = ref<any | null>(null)
    const descriptionsRef = ref<any | null>(null)
    const state = {
      option: {
        type: props.option.type,
        dataset: props.option.dataset,
        model: props.option.model
      },
      conArray: [{}]
    }

    

    const converData = (ob) => {
      let obKey = Object.keys(ob)
      for (let i = 0; i < obKey.length; i++) {
        let _index = {}
        _index['model'] = obKey[i]
        _index['value'] = ob[obKey[i]]
        _index['label'] = obKey[i]
        _index['hidden'] = false
        _index['tname'] = ''
        _index['span'] = 1
        state.conArray[i] = _index
      }
      return state.conArray
    }


    const getArrDifference = (arr1:any, arr2:any) =>{
      if(!arr1 || !arr2) return
      let _arr = [[],[]]
      
      // 查找新增中 新增的 字段
      
      arr2.forEach((item)=>{
        if(!arr1.includes(item)) _arr[0].push(item)
      })
      // 查找新增中 删除的 字段
      arr1.forEach(item=>{
        if(!arr2.includes(item)) _arr[1].push(item)
      })
      
      return _arr
    }

    const setOption = (data, isAppend) => { //分组关键字
      
      if (!data.dataset.source[0]) data.dataset = props.staticData
      state.option = { ...data }
      const selectData: any = _.cloneDeep(data)
      // 
      
      instance.update()
      
      if(selectData.model?.length == 0) { //组件初始化
        // selectData.innerKey = Object.keys(data.dataset.source[0])
        state.option.model = selectData.model = converData(data.dataset.source[0])
      } else if(state.option.model) {
        
        //获取差异
        let _diff: Diff = getArrDifference(state.option.model.map((obj,index) => {
          return obj.model
        }).join(",").split(','),data.dataset.source[0] ? Object.keys(data.dataset.source[0]) : Object.keys(data.dataset.source))
        //
        // if(_diff.length == 0) return
        
        _diff?.[0].map((item,index)=>{
          if(!state.option.model?.includes(item)) {
            
            let ob = data.dataset.source[0] ? data.dataset.source[0][item as any] : data.dataset.source[item as any]
            let _obj = {}
            _obj['model'] = item
            _obj['value'] = ob
            _obj['label'] = item
            _obj['hidden'] = false
            _obj['tname'] = ''
            _obj['span'] = 1
            state.conArray[state.conArray.length] = _obj
            state.option.model.push(_obj)
            selectData.model.push(_obj)
          }
        })
        _diff?.[1].map((items)=>{
          
          let _del
          selectData.model.forEach((item,index)=> {
            if(item.model == items) _del = index
          })
          if(_del) selectData.model.splice(_del,1)
            // delete selectData.model[items]
            
        })
        
      } else {
        selectData.model = data.model
      }

      delete selectData.dataset
      instance.proxy.option.model = selectData.model
      render(createVNode(IDescriptions(selectData)), boxRef.value)

    }
    onMounted(() => {
      instance.proxy.$el.__vueComponent = instance
      instance.setOption = setOption
    })

    // [
    //   {
    //     "model": "name",
    //     "label": "姓名",
    //     "value": "Peter",
    //     "span": 1
    //   },
    //   {
    //     "model": "sex",
    //     "label": "性别",
    //     "value": "男",
    //     "span": 1
    //   },
    //   {
    //     "model": "telephone",
    //     "label": "手机",
    //     "value": "18217111151",
    //     "span": 1
    //   }
    // ]

    const IDescriptions = (data) => () => h(Descriptions, {
      ref: descriptionsRef,
      layout: data.layout,
      size: data.size,
      bordered: data.bordered,
      column: data.column
      // ...(data as DescriptionsProps)
    },
      data.model.map((item, index) => {
        if(item.hidden) return
        return h(DescriptionsItem, {
          key: index,
          ref: descriptionsItemRef,
          label: item.tname ? item.tname : item.label,
          span:  item.span
          // value: value.value,
          // ...(data as DescriptionsItemProps) //DescriptionsItemProps
        }, () => item.value)
      })

    )

    const VNode = {
      render: () => h('div', {
        id: props.id,
        ref: boxRef,
      },
        // () => IDescriptions
      )
    }

    context.emit('finished', () => { return true });
    return () => h(VNode)
  }
}

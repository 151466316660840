import { r, d, h, a } from "@/units"
import { onMounted, getCurrentInstance, ref} from 'vue'
import _ from 'lodash'
export default {
  name: "ISubmit",
  props: {
    option: {
      type: Object as any,
      inner: String,
      dataset: Object
    },
    // staticData: Array,
    id: String,
  },
  emits: ['finished'],
  setup(props, context) {
    const instance: any = getCurrentInstance()
    const boxRef = ref<any | null>(null)
    const setOption = (data, isAppend) => { //分组关键字

      const selectData: any = _.cloneDeep(data)
      // delete selectData.dataset

      instance.update()


    }

    onMounted(() => {
      instance.proxy.$el.__vueComponent = instance
      instance.setOption = setOption
      console.log(instance)
    })
    
   
    const VNode = {
      render: () => h('div', {
        id: props.id,
        ref: boxRef,
      }
      )
    }

    context.emit('finished', () => { return true });
    return () => h(VNode)
  }
}

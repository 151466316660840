import VChart from 'vue-echarts'
import ITabs from '../components/IForm/ITabs'
import ITable from '../components/ITable'
import ITime from '../components/ITime'
import IFlap from '../components/IFlap'
import ILiquid from '../components/ILiquid'
import IInput from '../components/IForm/IInput'
import ISelect from '../components/IForm/ISelect'
import IRangePicker from '../components/IForm/IRangePicker'
import IButton from '@/components/IForm/IButton'
import IDigitalRange from '../components/IForm/IDigitalRange'
import IDatePicker from '../components/IForm/IDatePicker'
import IRadio from '../components/IForm/IRadio'
import ICheckbox from '../components/IForm/ICheckbox'
import ISwitch from '../components/IForm/ISwitch'
import ISubmit from '../components/IForm/ISubmit'
// import BaseText from '../components/BaseText'
import BaseText from '../components/BaseText/index.vue'
import ComplexText from '../components/ComplexText'
import IImage from '../components/IImage'
import IETable from '../components/IETable/index.vue'
import OutExcel from '../components/OutExcel'
import OutPrint from '../components/OutPrint'
import CarouselText from '../components/CarouselText'
import CarouselImage from '../components/CarouselImage'
import ChinaMap from '../components/ChinaMap'
import IDescriptions from '../components/IDescriptions'
import IWatch from '../components/IWatch'
import IData from '../components/IData'
import BoardList from '../components/Business/LanSiYu/BoardList'
import PlatformList from '../components/Business/LanSiYu/PlatformList'
import StationList from '../components/Business/LanSiYu/StationList'
import ForeignList from '../components/Business/LanSiYu/ForeignList'
import TailorList from '../components/Business/LanSiYu/TailorList'
import IframeModal from '../components/IframeModal/index.vue'
import TeamKanbanInfo from '../components/Business/LanSiYu/TeamKanbanInfo'
import StyleInfoCarousel from '../components/Business/LanSiYu/StyleInfoCarousel'
import MainConcepts from '../components/Business/XiangXing/MainConcepts/index.vue'
import MarketInformation from '../components/Business/XiangXing/MarketInformation/index.vue'
import MainProduct from '../components/Business/XiangXing/MainProduct/index.vue'
import DevMarketInformation from '../components/Business/XiangXing/DevMarketInformation/index.vue'
import Thermodynamic from '../components/Business/LanSiYu/Thermodynamic/index.vue'

import MachineEfficiency from '../components/Business/XiangXing/MachineEfficiency/index.vue'

import DevHorizontalMarketInformation from '../components/Business/XiangXing/DevHorizontalMarketInformation/index.vue'
// 新增 bar line 通用 组件
import LineBarCharts from '../components/LineBarCharts/index.vue'

// 翻牌器 动画化
import FlipperAnimate from '../components/FlipperAnimate/index.vue'

import { getComponentsType } from '@/utils'
/**
 * 
 * @param {String} type   组件类型
 * @param {String} subType  组件子类型
 * @returns 组件实例
 */
export default function useComponentType(type: string) {
  // const hasChart = type === 'chart'
  // const _type = hasChart ? type : subType ? `${type}-${subType}` : type
  const strType = getComponentsType(type, 1)
  const _type = strType[0] === "chart" ? strType[0] : type
  // 组件类型映射
  const typeMap = {
    chart: VChart,
    table: ITable,
    text: BaseText,
    complexText: ComplexText,
    time: ITime,
    image: IImage,
    flap: IFlap,
    liquid: ILiquid,
    outExcel: OutExcel,
    outPrint: OutPrint,
    carouselText: CarouselText,
    carouselImage: CarouselImage,
    descriptions: IDescriptions,
    chinaMap: ChinaMap,
    watch: IWatch,
    data: IData,
    'form-input': IInput,
    'form-select': ISelect,
    'form-rangePicker': IRangePicker,
    'form-inputNumber': IDigitalRange,
    'form-datePicker': IDatePicker,
    'form-radio': IRadio,
    'form-checkbox': ICheckbox,
    'form-switch': ISwitch,
    'form-tabs': ITabs,
    'form-button': ISubmit,
    'antd-table': IETable,
    'board-list': BoardList,
    'platform-list': PlatformList,
    'station-list': StationList,
    'foreign-list': ForeignList,
    'tailor-list': TailorList,
    'antd-modal': IframeModal,
    'form-btn': IButton,
    'team-kanban-info': TeamKanbanInfo,
    'style-info-carousel': StyleInfoCarousel,
    'main-concepts': MainConcepts,
    'market-information': MarketInformation,
    'main-product': MainProduct,
    'dev-market-information': DevMarketInformation,
    'thermodynamic': Thermodynamic,
    lineBarCharts: LineBarCharts,
    flipperAnimate: FlipperAnimate,
    machineEfficiency: MachineEfficiency,
    devHorizontalMarketInformation: DevHorizontalMarketInformation
  }
  return typeMap[_type] || null
}
import { r, d, h, a } from "@/units"
import { onMounted, getCurrentInstance, ref, watch, render, nextTick, computed, createVNode, TransitionGroup } from 'vue'
import type { PropType } from 'vue'
import { FormItemProps, FormItemInstance } from 'ant-design-vue/lib/form'
import { InputProps } from 'ant-design-vue/lib/input'
import { Input, Form, FormItem } from 'ant-design-vue'
// import { IFormOption  } from '@/store/modules/editor'
import './index.less'
import { getQueryVariable } from '@/utils'
export default {
  name: "forminput",
  props: {
    option: {
      type: Object as PropType<FormItemProps>,
      dataset: Object
    },
    // staticData: Array,
    id: String,
  },
  emits: ['finished'],
  setup(props, context) {
    const instance: any = getCurrentInstance()
    const boxRef = ref<any | null>(null)
    const inputRef = ref<any | null>(null)
    const formRef = ref<any | null>(null)
    const state = {
      option: {
        type: props.option.type
      }
    }
    const setOption = (data, isAppend) => { //分组关键字
      //以下为获取url参数，并设为默认值的逻辑
      const {parameter} = data
      const defaultValue = getQueryVariable(parameter)
      if (parameter && defaultValue){
        data.defaultValue = defaultValue
        instance.isValue = defaultValue
      }
      state.option = { ...data }
      boxRef.value.__vueComponent.props.option = { ...data }
      /* 删除 data 中的 dataset 对象 */

      delete data.dataset

      instance.update()

      render(createVNode(Forms(data)), boxRef.value)

      // new removeInitData(boxRef.value)
    }

    onMounted(() => {
      instance.proxy.$el.__vueComponent = instance
      instance.setOption = setOption
    })

    const removeInitData = (el: any) => {
      if (el.childNodes.length > 1) el.removeChild(el.childNodes[0])
    }

    const changeSize = (data: any) => {
      if(!data) return
      if(data == 'small') return { 'fontSize': '12px'}
      if(data == 'default') return { 'fontSize': '14px'}
      if(data == 'large') return { 'fontSize': '16px', 'lineHeight': 2.5}
    }
    const Forms = (data) => h(Form, {
      ref: formRef,
    },
      () => h(FormItem, {
        labelCol:{ style: changeSize(data.size) },
        ...(data as FormItemProps)
      },
        () => h(Input, {
          ref: inputRef,

          onChange: (e: any) => {
            instance.isValue = e.target.value
          },
          ...(data as InputProps)
        })
      )
    )

    const VNode = {
      render: () => h('div', {
        id: props.id,
        ref: boxRef,
      },
        () => Forms
      )
    }

    context.emit('finished', () => { return true });
    return () => h(VNode)
  }
}

import { ILocationInfo } from '@/types'
import { r, d, h, a } from '@/units'
import { onMounted, getCurrentInstance, ref, createVNode, render, PropType } from 'vue'

export default ({
  name: 'IImage',
  props: {
    option: {
      type: Object
    },
    locationInfo: Object as PropType<ILocationInfo>
  },
  setup(props, context) {
    const instance: any = getCurrentInstance()
    const boxRef = ref<any | null>(null)
    const imgRef = ref<any | null>(null)


    const state = {
      option: {
        type: props.option.type,
        src: props.option.srcConfig
      },
    }

    const setOption = (obj, a) => {
      state.option = { ...obj }
      // console.log(state.option,imgRef)
      instance.props.option = obj
      // instance.update()
      // instance.proxy.$forceUpdate()
      render(createVNode('img',{
        ...obj,
        style:imgPosition(obj)
      }), boxRef.value)
    }

    const imgPosition = (style) => {
      let _position
      if(style.backgroundPosition == 'center center') {
        _position = {
          margin: '0 auto',
          alignSelf:'center'
        }
      } else if(style.backgroundPosition == 'top left') {
        _position = {
          margin: '0',
          alignSelf:'baseLine'
        }
      } else if(style.backgroundPosition == 'top right') {
        _position = {
          margin: '0 0 0 auto',
          alignSelf:'baseLine'
        }
      }
      return {
        ..._position,
        height: `${style.backgroundImageHeight}px`,
        width: `${style.backgroundImageWidth}px`,
      }
    }

    onMounted(() => {
      instance.proxy.$el.__vueComponent = instance
      instance.setOption = setOption
    })

    const Images = (data) => createVNode('img', {
      ref: imgRef,
      src: props.option.src
    }
    )

    const VNode = {
      render: () => h('div', {
        id: props.id,
        ref: boxRef,
        style: {
          height:'inherit',
          width:'inherit',
          display:'flex',
          alignItems:'center',
          lineHeight: `${props.locationInfo?.height}px`
        },
      },
        // () => Images
      )
    }

    context.emit('finished', () => {
      return true
    })
    return () => createVNode(VNode)
  },
})
